// React related
import { createContext, useEffect, useReducer } from 'react'
// Context
// Libraries
// Database or services
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth';
// Components
// Pages
// Hooks
// Styles

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null }
    case 'AUTH_IS_READY':
      return { ...state, user: action.payload, authIsReady: true }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false
  })
  // console.log('AuthContext state:', state)

  // 網頁會根據AuthContext狀態中的user決定渲染哪些組件：
  // 例如使用login()觸發在dispatch({type:'LOGIN',payload:userCredential.user})並在context狀態內保存返回的user憑證，所以我們能從context中取得user，但這只是登入完成時的一個瞬時操作，並沒有持久化儲存登入狀態。在頁面刷新後，React 會重新渲染並重新建立所有元件，這會導致先前儲存在狀態中的資料被清除， state內容就初始化了。
  // 為了重新整理頁面後保持使用者的登入狀態，使用useEffect在頁面載入時監聽使用者的登入狀態並將其儲存到應用程式的狀態管理中，從而在應用程式中保持使用者已登入的狀態，並且能夠根據使用者的登入狀態來渲染不同的元件。

  // TODO authIsReady機制
  // 1.默認狀態 authIsReady(false)
  // 2.頁面加載 onAuthStateChanged
  // ...state, user: action.payload, authIsReady(true) 
  // 備註：就算驗證為未登入狀態,authIsReady一樣會設置為true
  // 3.在App.js設置 authIsReady為true才會渲染頁面
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: 'AUTH_IS_READY', payload: user })
      unsub() //只在元件載入時監聽一次並立即取消監聽器，避免重複註冊監聽
    });
  }, [])

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}