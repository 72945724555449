// React related
import React, { useEffect, useState } from 'react'
// Context
// Libraries
// Database or services
// Styles
import style from './_scrollToTop.module.scss'
// Components
// Pages
// Hooks

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        if (window.scrollY > 200) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)
        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])

    return (
        <div className={style.scrollToTop}>
            {
                isVisible && (
                    <span onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }}>
                        <i className="bi bi-chevron-bar-up"></i>
                    </span>
                )
            }
        </div>
    )
}
