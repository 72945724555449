// React related
import { useState, useEffect } from 'react'
// Context
// Libraries
// Database or services
import { firestore } from "../firebase/config"
import { onSnapshot, doc } from 'firebase/firestore';
// Styles
// Components
// Pages
// Hooks

export const useDocument = (collectionName, documentId) => {
    const [documentData, setDocumentData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!documentId) return;

        const docRef = doc(firestore, collectionName, documentId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            try {
                if (snapshot.exists()) {
                    setDocumentData({ ...snapshot.data(), id: snapshot.id });
                    setError(null);
                } else {
                    setError('找不到相應的文件');
                }
            } catch (error) {
                console.log(error);
                setError('請求資料時發生錯誤');
            }
        });

        return unsubscribe;
    }, [collectionName, documentId]);

    return { documentData, error };
};
