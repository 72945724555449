// React related
import { useReducer, useEffect, useState } from "react"
// Context
// Libraries
import { toast } from 'react-toastify';
// Database or services
import { storage } from "../firebase/config"
import { getDownloadURL, ref, uploadBytesResumable, listAll, deleteObject } from '@firebase/storage';
// Components
// Pages
// Styles
// Hooks

// 初始狀態 (在hook外創建，因為不需要每次使用hook時都創建副本)
let initialState = {
    file: null,
    isPending: false,
    error: null,
    success: null,
}

// 定義reducer
// Reducer函數 可以想像是useState裡面的setter，要改變其一屬性的時候，先展開全數屬性後，再根據要修改的key複寫新的value
const storageReducer = (state, action) => {
    switch (action.type) {
        case "IS_PENDING":
            return { success: false, isPending: true, error: null, file: null }
        case "ERROR":
            return { success: false, isPending: false, error: action.payload, file: null }
        case "UPLOAD_FILE":
            return { success: true, isPending: false, error: null, file: action.payload }
        default:
            return state
    }
}

export const useStorage = () => {
    // 使用useReducer管理"回應"狀態，並定義useReducer #reducer 
    const [response, dispatch] = useReducer(storageReducer, initialState)
    // 檢查組件是否被卸載
    const [isCancelled, setIsCancelled] = useState(false)

    // 只有元件未卸載的情況下才dispatch
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // 上傳檔案+監聽上傳狀態
    const uploadFile = async (file, path) => {
        try {
            const storageRef = ref(storage, path + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed', (snapshot) => {
                handleUploadStateChange(snapshot, uploadTask, file);
            });
        } catch (error) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: error.message });
        }
    };

    // 上傳狀態進度變化
    const handleUploadStateChange = async (snapshot, uploadTask, file) => {
        const currentProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100 * 100) / 100;
        switch (snapshot.state) {
            case 'paused':
                console.log('已暫停上傳');
                break;
            case 'running':
                return handleProgress(uploadTask, file, currentProgress);
            case 'error':
                const errorMessage = '執行上傳檔案時出現錯誤';
                dispatchIfNotCancelled({ type: 'ERROR', payload: errorMessage });
                console.log('上傳出錯');
                break;
            default:
                break;
        }
    };

    const handleProgress = async (uploadTask, file, currentProgress) => {
        try {
            console.log(`正在上傳${file.name}－${currentProgress}%`);
            if (currentProgress === 100) {
                toast.success(`已上傳檔案 ${file.name}`);
                const uploadedFile = uploadTask.snapshot.ref;
                const url = await getDownloadURL(uploadedFile);
                dispatchIfNotCancelled({ type: 'UPLOAD_FILE', payload: url });
            }
        } catch (error) {
            const errorMessage = '取得檔案連結時出現錯誤';
            dispatchIfNotCancelled({ type: 'ERROR', payload: errorMessage });
            console.log(errorMessage, error);
        }
    };

    // 刪除資料夾
    const deleteFolder = async (folderPath) => {
        const folderRef = ref(storage, folderPath);

        try {
            const { items } = await listAll(folderRef);

            // 遍歷資料夾內的每個檔案和子資料夾，逐一刪除
            const deletePromises = items.map(async (item) => {
                if (item.child) {
                    await deleteFolder(item.fullPath); // 如果是子資料夾，遞迴刪除
                } else {
                    await deleteObject(item); // 如果是檔案，刪除該檔案
                }
            });

            // 等待所有檔案和資料夾都刪除完成
            await Promise.all(deletePromises);

            console.log(`資料夾 ${folderPath} 已成功刪除`);
        } catch (error) {
            console.error('刪除資料夾時出現錯誤：', error);
        }
    };

    //這個返回的函數會在組件卸載時執行
    useEffect(() => {
        return () => {
            setIsCancelled(true)
        };
    }, []);

    return { uploadFile, deleteFolder, response }
}
