import React, { useState, useEffect } from 'react';
import style from './_spinner.module.scss'
export default function Spinner() {
    const [showReload, setShowReload] = useState(false)
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowReload(true);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <div className={`${style.spinnerGroup}`} >
            <div className="spinner-border mt-5 spinner" role="status">
                <span className='visually-hidden'>載入中...</span>
            </div>
            {showReload && <p>點擊 <span onClick={() => {
                window.location.reload()
            }}>重新加載</span> 頁面</p>}
        </div>
    )
}
