// React related
import React, { useEffect, useState } from 'react';
// Context
// Libraries
import { toast } from 'react-toastify';
// Database or services
// Styles
import style from './_auth.module.scss'
// Components
// Pages
// Hooks
import { useSignup } from '../../hooks/useSignup'
import { useLogin } from '../../hooks/useLogin'
import { useAuthContext } from "../../hooks/useAuthContext"
import { useLogout } from "../../hooks/useLogout"

const initialState = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    adminPwd: '',
}

export default function Auth({ setNavActive }) {
    const [state, setState] = useState(initialState) //定義表單內容
    const { displayName, email, password, confirmPassword, adminPwd } = state;
    const [signUp, setSignUp] = useState(false) //登入狀態
    // 從自定義hook中提取變數與方法
    const { signup } = useSignup()
    const { login } = useLogin()
    const { logout } = useLogout()
    const { user } = useAuthContext()

    useEffect(() => {
        setNavActive('auth')
    })

    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const toggleSignUp = () => {
        setSignUp((prevSignUp) => !prevSignUp);
        setState(initialState);
    }

    const handleAuth = async (e) => {
        e.preventDefault();
        try {
            if (adminPwd === process.env.REACT_APP_ADMIN_PWD) {
                if (!signUp) {
                    // 執行登入操作
                    if (!(email && password)) {
                        throw new Error("請確認email與密碼欄位完成填寫")
                    }
                    login(email, password)
                } else {
                    // 執行註冊操作
                    if (password !== confirmPassword) {
                        throw new Error("確認密碼與密碼設定不符")
                    }

                    if (!(displayName && email && password)) {
                        throw new Error("請確認所有欄位都已完成填寫")
                    }
                    signup(email, password, displayName)
                }
            } else {
                throw new Error('操作失敗請洽管理員')
            }
        } catch (error) {
            // console.log(error);
            toast.error(error.message); // 使用 toast.error 顯示錯誤訊息
        }
    }

    return (
        <div className="BelowNav">
            <div className="container py-5">
                {user ? <>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-10 col-md-8 col-lg-6 text-center">
                            <p className={style.title}>目前登入帳號</p>
                            <div className="col-12 py-3">
                                {user.displayName}
                            </div>
                            <div className="col-12 py-3">
                                {user.email}
                            </div>
                            <div className="col-12 py-3">
                                <button
                                    className={style.logoutBtn}
                                    type="submit"
                                    onClick={logout}
                                >
                                    登出
                                </button>
                            </div>
                        </div>
                    </div>
                </> : <>
                    {!signUp
                        ? <p className={style.title}>登入</p>
                        : <p className={style.title}>註冊</p>}
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-10 col-md-8 col-lg-6">
                            {/* {JSON.stringify(state)} */}
                            <form onSubmit={handleAuth} className={`row ${style.form}`}>
                                {signUp && (
                                    <>
                                        <div className="col-12 py-3">
                                            <input
                                                type="text"
                                                className='form-control input-text-box'
                                                placeholder='顯示名稱'
                                                name='displayName'
                                                autoComplete='off'
                                                value={displayName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="col-12 py-3">
                                    <input
                                        type="email"
                                        className='form-control input-text-box'
                                        placeholder='Email'
                                        name='email'
                                        autoComplete='username'
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 py-3">
                                    <input
                                        type="password"
                                        className='form-control input-text-box'
                                        placeholder='密碼'
                                        name='password'
                                        autoComplete='current-password'
                                        value={password}
                                        onChange={handleChange}
                                    />
                                </div>
                                {signUp && (
                                    <div className="col-12 py-3">
                                        <input
                                            type="password"
                                            className='form-control input-text-box'
                                            placeholder='再次確認密碼'
                                            name='confirmPassword'
                                            autoComplete='new-password'
                                            value={confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}

                                <div className="col-12 py-3">
                                    <input
                                        type="password"
                                        className='form-control input-text-box'
                                        placeholder='管理員密碼'
                                        name='adminPwd'
                                        autoComplete='off'
                                        value={adminPwd}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 py-3 text-center">
                                    <button
                                        className={`btn ${!signUp ? "btn-sign-in" : "btn-sign-up"}`}
                                        type="submit"
                                    >
                                        {!signUp ? "登入" : "註冊"}
                                    </button>
                                </div>
                            </form>
                            <div>
                                {!signUp ? (<>
                                    <div className="text-center justify-content-center mt-2 pt-2">
                                        <p className='small fw-bold mt-2 pt-1 mb-0'>
                                            還沒有帳號嗎？&nbsp;
                                            <span
                                                className=''
                                                style={{ textDecoration: "none", cursor: "pointer", color: "#298af2" }}
                                                onClick={toggleSignUp}
                                            >
                                                註冊
                                            </span>
                                        </p>
                                    </div>
                                </>) : (
                                    <>
                                        <div className="text-center justify-content-center mt-2 pt-2">
                                            <p className='small fw-bold mt-2 pt-1 mb-0'>
                                                已經擁有帳號嗎？ &nbsp;
                                                <span
                                                    style={{ textDecoration: "none", cursor: "pointer", color: "#298af2" }}
                                                    onClick={toggleSignUp}
                                                >
                                                    登入
                                                </span>
                                            </p>
                                        </div>
                                    </>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </>}

            </div>
        </div>
    )
}
