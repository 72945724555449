// React related
import { useEffect, useState } from 'react'
// Context
import { useAuthContext } from './useAuthContext'
// Libraries
import { toast } from 'react-toastify';
// Database or services
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth';
// Styles
// Components
// Pages
// Hooks

export const useLogin = () => {

    const [loginError, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    // 檢查組件是否被卸載
    const [isCancelled, setIsCancelled] = useState(false)

    // 調用hooks得到useContext(AuthContext)的結果= AuthContext.Provider 提供的值
    const { dispatch } = useAuthContext()

    const login = async (email, password) => {
        setError(null)
        setIsPending(true)

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)

            dispatch({ type: 'LOGIN', payload: userCredential.user })

            if (!isCancelled) {
                setIsPending(false)
                setError(null)
                toast.success(`登入成功！${userCredential.user.displayName}`)
            }
        }
        catch (err) {
            if (!isCancelled) {
                setError(err.message)
                setIsPending(false)
                toast.error(`登入失敗`)
            }
        }
    }

    useEffect(() => {
        //這個返回的函數會在組件卸載時執行
        return () => {
            setIsCancelled(true)
        };
    }, [])

    return { login, loginError, isPending }
}