import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import './_SearchPanel.scss'

export default function SearchPanel({ setSearchPanel }) {
    const navigate = useNavigate()

    //輸入框
    const [searchInput, setSearchInput] = useState('')
    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === 'Escape') {
                setSearchPanel('close')
            }
        };

        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [setSearchPanel]);

    useEffect(() => {
        document.querySelector('.formInput').focus()
    }, [])

    return (
        <div className="searchFormContainer">
            <div className="formContainerInner">
                <form action="" className="form">
                    <input
                        type="text"
                        className="formInput"
                        placeholder="搜尋關鍵字..."
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value)
                        }}
                    />
                    <button className="formBtn" type="submit"
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(`/blogs?q=${searchInput}`)
                            setSearchInput('')
                            setSearchPanel('close')
                        }}>
                        <i className='bi bi-search'></i>
                    </button>
                </form>
                <span className="formNote">按下 ESC鍵 關閉</span>
            </div>
            <button className="formCloseBtn placeItemsCenter" onClick={() => { setSearchPanel('close') }} >
                <i className='bi bi-x-lg'></i>
            </button>
        </div>

    )
}
