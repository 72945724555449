// React related
import { useState, useReducer, useEffect, useRef } from 'react'
// Context
// Libraries
// Database or services
import { firestore } from "../firebase/config"
import { collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore';
// Styles
// Components
// Pages
// Hooks


// 初始狀態 (在hook外創建，因為不需要每次使用hook時都創建副本)
let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null,
}

// 定義reducer
// Reducer函數 可以想像是useState裡面的setter，要改變其一屬性的時候，先展開全數屬性後，再根據要修改的key複寫新的value
const firestoreReducer = (state, action) => {
    switch (action.type) {
        case "IS_PENDING":
            return { success: false, isPending: true, error: null, document: null }
        case "ERROR":
            return { success: false, isPending: false, error: action.payload, document: null }
        case "FETCHED_DOCUMENT":
            return { success: true, isPending: false, error: null, document: action.payload }
        default:
            return state
    }
}


export const useCollection = (collectionName) => {
    // 使用useReducer管理"回應"狀態，並定義useReducer #reducer 
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    // 檢查組件是否被卸載
    const [isCancelled, setIsCancelled] = useState(false)


    // 只有元件未卸載的情況下才dispatch
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            // console.log('Dispatching action:', action);
            dispatch(action);
        } else {
            // console.log('Dispatch cancelled due to component unmount');
        }
    };

    // 請求指定條件資料
    const fetchDocument = (_queryConditions, _orderByConditions, _limit) => {

        let ref = collection(firestore, collectionName);
        if (_queryConditions) {
            // console.log('_queryConditions', true);
            _queryConditions.forEach(condition => {
                ref = query(ref, where(...condition));
            });
        }

        //- 使用orderBy功能需要在 Firebase控制台的建立索引
        // 如果沒有建立索引的話會讀不到資料，這時候會發現主控台打印firebase的訊息(如下)，可以點擊控制台顯示的鏈接前往Firebase 控制台的索引頁面，在那裡創建適當的索引以支援查詢。(進入頁面後會自動跳入建立索引的視窗，確認無誤後按建立即可，建立需要5-10分鐘左右，重新載入頁面即可察看結果。)
        // >> useCollection.js:32 [2023-12-25T15:29:40.028Z]  @firebase/firestore: Firestore (10.7.1): Uncaught Error in snapshot listener: FirebaseError: [code=failed-precondition]: The query requires an index. That index is currently building and cannot be used yet. See its status here: https://console.firebase.google.com/v1/r/project/n018-finance-tracker/firestore/indexes?create_composite...

        if (_orderByConditions && _orderByConditions.length > 0) {
            _orderByConditions.forEach(condition => {
                ref = query(ref, orderBy(...condition));
            });
            // console.log(_orderByConditions);
        }


        // 根据传入的 _limit 参数动态设置限制数量
        if (_limit) {
            ref = query(ref, limit(_limit));
        }

        // 實時監聽collection資料集合變化
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            if (!isCancelled) {
                dispatch({ type: "IS_PENDING" })
                try {
                    let results = []
                    snapshot.docs.forEach(doc => {
                        results.push({ ...doc.data(), id: doc.id })
                    });
                    // update state
                    dispatchIfNotCancelled({ type: 'FETCHED_DOCUMENT', payload: results })
                } catch (error) {
                    console.log(error);
                    dispatchIfNotCancelled({ type: "ERROR", payload: error.message })
                }
            }
        });
        return () => {
            // console.log(`取消監聽'/${collectionName}'`);
            unsubscribe()
        }
    }

    //這個返回的函數會在組件卸載時執行
    useEffect(() => {
        return () => {
            setIsCancelled(true)
        };
    }, []);

    return { fetchDocument, response }
}