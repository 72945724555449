import { Link } from 'react-router-dom'
import style from './_blogs.module.scss'
import { useCategoryContext } from '../../hooks/useCategoryContext';

export default function Category() {
    const { categoryList } = useCategoryContext()

    return (
        <div className={style.categoryGroup}>
            <ul >
                {
                    categoryList.map((category, idx) => (
                        <Link to={`/blogs/category/${category.category}`} key={idx}>
                            <li>
                                <p>{category.category}</p>
                                <span>({category.count})</span>
                            </li>
                        </Link>
                    ))
                }
            </ul>
        </div>
    )
}
