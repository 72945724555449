import { useEffect, useState } from 'react'
import style from '../../components/panel/_Panel.module.scss'
import { useFirestore } from '../../hooks/useFirestore'
import { ToastContainer, toast } from 'react-toastify';

export default function EditCategories({ categories }) {

    const [categoryList, setCategoryList] = useState([])
    useEffect(() => { setCategoryList(categories) }, [categories])

    const [newCategory, setNewCategory] = useState('') //新分類輸入框的值
    const [editStates, setEditStates] = useState({}) // 使用物件來跟蹤編輯狀態

    const { addDocument, deleteDocument, updateDocument } = useFirestore('blogCategories')
    const [error, setError] = useState(false)
    if (error) { toast.error(error) }

    // 新增分類
    const handleAddCategory = (e) => {
        e.preventDefault()
        try {
            if (newCategory !== "") {
                addDocument({ category: newCategory })
                toast.success('新增成功')
                setNewCategory('')

            } else {
                setError('分類名稱不得為空')
            }
        } catch (error) {
            setError(error.message)
        }
    }

    // 輸入內容變更
    const handleInputChange = (categoryId, newValue) => {
        setCategoryList((prevCategoryList) =>
            prevCategoryList.map((category) =>
                category.categoryId === categoryId
                    ? { ...category, category: newValue } // Update the category name
                    : category
            )
        );
    };

    // 儲存 handleSave
    const handleSaveCategory = async (categoryId) => {
        try {
            //利用categoryId 在 categoryList尋找相同ID的值
            const originValue = categories.find(category => category.categoryId === categoryId).category;
            const newValue = categoryList.find(category => category.categoryId === categoryId).category;
            await updateDocument(categoryId, { category: newValue });
            if (originValue !== newValue) { toast.success('修改完成'); }
            setEditStates((prevEditStates) => ({
                ...prevEditStates,
                [categoryId]: false, // Exit edit mode after saving changes
            }));
        } catch (error) {
            toast.error(error.message);
        }
    };

    // 刪除分類
    const handleRemoveCategory = (categoryId, index) => {
        const confirmed = window.confirm(`請確定是否刪除分類 ${categoryList[index].category} `)
        if (confirmed) {
            if (categoryList[index].count === 0) {
                try {
                    deleteDocument(categoryId)
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                setError('無法刪除有文章的分類')
            }
        }
    }


    return (
        <>
            <ToastContainer autoClose={2000} position="top-center" />
            <div className={`row justify-content-center`}>
                <div className="col-10">
                    <p className="title mb-5">分類管理</p>
                    {/* <p className="text-wrap">categoryList<br /> {JSON.stringify(categoryList)}</p> */}
                    <div className="row align-items-center justify-content-center">
                        <ul className="col-12 mt-2 " style={{ width: "400px" }}>
                            <li className='d-flex align-items-center justify-content-center my-2'>
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className={style.formInput}
                                        placeholder='新增'
                                        value={newCategory}
                                        onChange={(e) => { setNewCategory(e.target.value) }}
                                    />
                                </div>
                                <div className="col-2 d-flex">
                                    <button
                                        className={`${style.formBtn} ms-2`}
                                        onClick={handleAddCategory}
                                    >
                                        <i className="bi bi-plus-lg"></i>
                                    </button>
                                </div>
                            </li>

                            {categoryList.map((item, index) => (
                                <li key={item.categoryId} className='d-flex align-items-center my-2'>
                                    <div className="col-10">
                                        {editStates[item.categoryId] ? (
                                            // 分類(編輯模式)
                                            <input
                                                type="text"
                                                className={style.formInput}
                                                placeholder={item.category}
                                                onChange={(e) => {
                                                    handleInputChange(item.categoryId, e.target.value)
                                                }}
                                                disabled={!editStates[item.categoryId]}
                                                value={item.category}
                                            />
                                        ) : (
                                            <span>{item.category} ({item.count})</span>
                                        )}
                                    </div>
                                    <div className="col-2 d-flex">

                                        {editStates[item.categoryId] ? (
                                            //儲存 + 刪除
                                            <>
                                                <button
                                                    className={`${style.formBtn} ms-2`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSaveCategory(item.categoryId, index)
                                                    }
                                                    }>
                                                    <i className="bi bi-check2"></i>
                                                </button>
                                                <button
                                                    className={`${style.formBtn} ms-2`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRemoveCategory(item.categoryId, index)
                                                    }
                                                    }>
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </>
                                        ) : (
                                            //編輯
                                            <button
                                                type='button'
                                                className={`${style.formBtn} ms-2`}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setEditStates(prev => ({ ...prev, [item.categoryId]: true }));
                                                }}
                                            >
                                                <i className="bi bi-pencil-square" />
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div >
                    </div>
                </div>

                <hr className='my-5' />
            </div >
        </>
    )
}
