import React, { useEffect, useState } from 'react'
import style from './_pagination.module.scss'
import Spinner from '../../components/spinner/Spinner';

export default function Pagination({ dataPerPage, totalData, activePage, paginate }) {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <div className={style.pagination}>
            <button
                className="page-item"
                onClick={() => { if (activePage > 1) { paginate(activePage - 1) } }}
                disabled={activePage === 1}
            >
                <span className="page-link">
                    <i className="bi bi-caret-left-fill"></i>
                </span>
            </button>
            <select
                className="page-item"
                value={activePage}
                onChange={(e) => paginate(e.target.value)}
            >
                {totalData &&
                    pageNumbers.map((number, i) => (
                        <option value={i + 1} key={i} className="page-link">
                            {i + 1}
                        </option>
                    ))}
            </select>
            <button
                className="page-item"
                onClick={() => { if (activePage < pageNumbers.length) { paginate(activePage + 1) } }}
                disabled={activePage === pageNumbers.length}
            >
                <span className="page-link">
                    <i className="bi bi-caret-right-fill"></i>
                </span>
            </button>
        </div>
    )
}