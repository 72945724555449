// React related
import { useState } from 'react';
import { Link } from "react-router-dom"
// Context
// Libraries
// Database or services
// Components
import SearchPanel from './SearchPanel';
// Styles
import './_Navbar.scss'
// Hooks
import { useAuthContext } from "../../hooks/useAuthContext"

export default function Navbar({ navActive, theme, setTheme }) {
    const { user } = useAuthContext()
    const [menuState, setMenuState] = useState('close')
    const [searchPanel, setSearchPanel] = useState('close')
    const getNavlinkStyle = (path) => { if (navActive === path) { return 'active' } }
    const toggleMenu = () => { menuState === 'open' ? setMenuState('close') : setMenuState('open') }
    const toggleTheme = () => { theme === 'dark' ? setTheme('light') : setTheme('dark') }

    return (
        <header className='header px-4'>
            <nav className={`navbar `}>
                {/* LOGO*/}
                {/* <Link to="/" className={styles.titleWithImg}>
                        <h1>google</h1>
                    </Link> */}
                <div className='placeItemsCenter py-2' id='logo'>
                    <Link to="/" className='d-flex flex-nowrap' >
                        <h1 className='title' style={{ fontSize: '3.5rem' }} >
                            荒碼求生
                        </h1>
                        <span style={{ marginTop: '0.5rem', fontSize: '1rem' }} >@CHEN</span>
                    </Link>
                    {user ? <Link to="/auth" className='d-none d-lg-flex' ><span><i className="bi bi-person-lock"></i>{user.displayName}</span> </Link> : ""}
                </div>

                {/* 主要選單 */}
                <div className={`navMenu ${menuState === 'open' ? " active" : ""}`}>
                    <ul className='p-0 mt-md-3'>
                        <li>
                            <Link to="/" className={getNavlinkStyle('home')} onClick={toggleMenu}>
                                首頁
                            </Link>
                        </li>
                        <li>
                            <Link to="/blogs" className={getNavlinkStyle('blogs')} onClick={toggleMenu}>
                                文章
                            </Link>
                        </li>
                        <li>
                            <Link to="/repos" className={getNavlinkStyle('repos')} onClick={toggleMenu}>
                                數據庫
                            </Link>
                        </li>
                        {/* <li className="listItem screenLgHidden">
                            <NavLink to="/login" className="listLink">登入</NavLink>
                        </li>
                        <li className="listItem screenLgHidden">
                            <NavLink to="/signup" className="listLink">註冊</NavLink>
                        </li> */}
                    </ul>
                </div>
                {/* 功能選單 */}
                <div className='navList  '>
                    <button type="button" onClick={toggleTheme}>
                        <i className={`bi bi-sun sun-icon ${theme === 'light' ? "d-none" : "d-block"}`}></i>
                        <i className={`bi bi-moon moon-icon ${theme === 'dark' ? "d-none" : "d-block"}`}></i>
                    </button>
                    <button type="button" onClick={() => {
                        return setSearchPanel('open')
                    }}>
                        <i className="bi bi-search search-icon"></i>
                    </button>
                    <button className="toggleMenuIcon" type="button" onClick={toggleMenu}>
                        <i className={`bi bi-list openMenuIcon ${menuState === 'open' ? "d-none" : "d-block"}`}></i>
                        <i className={`bi bi-x-lg closeMenuIcon ${menuState === 'close' ? "d-none" : "d-block"}`}></i>
                    </button>
                </div>
            </nav>
            {/* 搜尋面板 */}
            {searchPanel === 'open' && <SearchPanel setSearchPanel={setSearchPanel} />}
        </header>
    )
}