// React related
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
// Context
// Libraries
import MarkdownEditor from '@uiw/react-markdown-editor';
import { ToastContainer, toast } from 'react-toastify';
import { formattedDate } from '../../utility/index'
// Database or services
// Components
import Panel from '../../components/panel/Panel';
import EditCategories from './EditCategories';
import ReactTagInput from '@pathofdev/react-tag-input';
import ObjectField from './ObjectField';
// Styles
import style from './_addEditBlog.module.scss'
import '@pathofdev/react-tag-input/build/index.css'
import './_customReactTags.scss'
// Hooks
import { useCategoryContext } from '../../hooks/useCategoryContext';
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'
import { useDocument } from '../../hooks/useDocument';

const initialState = {
    title: "",
    description: "",
    category: "6UMNc446VEeFwJw7AIPf",
    coverImgUrl: "",
    ImgUrls: [],
    content: "",
    tags: [],
    relatedLinks: [],
    isPublic: false,
    isDraft: true,
    displayOnHomePage: false,
    createdAt: "",
    updatedAt: "",
}

export default function AddEditBlog({ setNavActive }) {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const { categoryList } = useCategoryContext() //分類表 
    const pathSegments = location.pathname.split('/')[2];
    const [editMode, setEditMode] = useState('null')
    const { documentData } = useDocument('blogs', id)

    const { addDocumentWithCustomID, deleteDocument, updateDocument, response } = useFirestore('blogs')
    const { uploadFile, response: storagResponse, deleteFolder } = useStorage()
    // const { documents: blogCategories } = useCollection('blogCategories', null, ["createdAt", "asc"])

    const [form, setForm] = useState(initialState)  //定義表單內容
    const { title, description, category, coverImgUrl, ImgUrls, content, tags, relatedLinks, isPublic, isDraft, displayOnHomePage, createdAt, updatedAt } = form;
    const [panel, setPanel] = useState('close')
    const [fileTarget, setFileTarget] = useState('coverImgUrl')
    const [imgPreview, setImgPreview] = useState(null)

    const [isPending, setIsPending] = useState(false)
    const [isSave, setIsSave] = useState(true)

    //獲取文章ID和編輯模式
    useEffect(() => {
        setNavActive('blogs')
        setEditMode(pathSegments)
        return () => { };
    }, [pathSegments]);

    useEffect(() => {
        if (editMode === 'edit' && documentData) {
            setForm({ ...documentData });
        }

        return () => { };
    }, [editMode, documentData]);



    // 上傳圖片
    const handleUpload = async (e) => {
        if (id) {
            setIsSave(false)
            const { name } = e.target;
            if (name === 'coverImgUrl') {
                setFileTarget('coverImgUrl')
            } else if (name === 'ImgUrls') {
                setFileTarget('ImgUrls')
            }
            await uploadFile(e.target.files[0], `blogs/${id}/`)
        }
    }

    // 監聽上傳進度
    useEffect(() => {
        if (storagResponse.success) {
            setIsPending(true)
            if (fileTarget === 'coverImgUrl') {
                setForm({ ...form, coverImgUrl: storagResponse.file });
                setIsPending(false)
            } else if (fileTarget === 'ImgUrls') {
                const updatedImgUrls = [...ImgUrls, storagResponse.file];
                setForm({ ...form, ImgUrls: updatedImgUrls });
                setIsPending(false)
                if (ImgUrls.length === 0) { setImgPreview(storagResponse.file) }
            }
        }
    }, [storagResponse, isPending]);



    // 表單變更
    const handleChange = (e) => {
        e.preventDefault();
        if (id) {
            setIsSave(false);
            const { name, value, type } = e.target;

            if (type === 'radio') {
                if (name === "isPublic") {
                    const isPublicValue = value === 'yes';
                    setForm({ ...form, isPublic: isPublicValue });
                } else if (name === "isDraft") {
                    const isDraftValue = value === 'yes';
                    setForm({ ...form, isDraft: isDraftValue });
                } else if (name === "displayOnHomePage") {
                    const displayOnHomePageValue = value === 'yes';
                    setForm({ ...form, displayOnHomePage: displayOnHomePageValue });
                }
            } else {
                if (name === "category") {
                    setForm({ ...form, category: value });
                } else {
                    setForm({ ...form, [name]: value });
                }
            }
        }
    };

    //確保值變化時表單會更新(建議依賴項不要放會經常變化的欄位)
    useEffect(() => {
        setForm(prevForm => ({ ...prevForm, coverImgUrl, ImgUrls, isPublic, isDraft, displayOnHomePage }));
    }, [coverImgUrl, ImgUrls, isPublic, isDraft, displayOnHomePage]);



    // 表單變更 標籤
    const setTags = (tags) => {
        setIsSave(false)
        setForm({ ...form, tags })
    }

    // 提交表單
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!id) { throw new Error('出現錯誤查無文章ID') }
        try {
            const fieldNames = {
                title: '標題',
                description: '簡述',
                category: '分類',
                coverImgUrl: '封面圖片',
                content: '內文',
                tags: '標籤'
            };
            const requiredFields = ['title', 'description', 'category', 'content', 'tags'];
            const emptyFields = requiredFields.filter(field => !form[field]);
            if (emptyFields.length > 0) {
                const emptyFieldNames = emptyFields.map(field => fieldNames[field]);
                const errorMessage = `欄位未填寫: ${emptyFieldNames.join(', ')} `;
                toast.error(errorMessage);
            } else {
                if (editMode === 'create') {
                    addDocumentWithCustomID(id, { ...form });
                    toast.success('已新增文章')
                    navigate(`/blogs/edit/${id}`);
                } else if (editMode === 'edit') {
                    updateDocument(id, { ...form })
                    setIsSave(true)
                    toast.success('已修改文章變更')
                    navigate(`/blogs/${id}`)
                }
            }
        } catch (error) {
            toast.error(error.message);
        }
    }


    const handleCancel = (e) => {
        e.preventDefault();
        const confirmCancel = window.confirm('是否放棄本次變更')

        if (confirmCancel) {
            const destination = editMode === 'edit' ? `/blogs/${id}` : `/blogs`;
            navigate(destination);
        }
    }


    const handleDelete = (e) => {
        e.preventDefault();
        const cofirmDelete = window.confirm(`是否刪除文章 ${title}`)
        if (cofirmDelete) {
            deleteFolder(`blogs/${id}/`)
            deleteDocument(id)
            toast.success(`已刪除文章 ${title} (${id})`)
            navigate(`/blogs`)
        }
    }

    return (
        <div className="BelowNav">
            <ToastContainer autoClose={2000} position="top-center" />
            <div className="container py-5">
                <div className="row  justify-content-center align-items-center">
                    <div className="text-center mb-3">
                        <span className={`${style.title}`}>{editMode === 'create' ? '新增文章' : '編輯文章'}</span>
                    </div>

                    <div className="col-12 col-md-10 col-lg-8">
                        {/* <p>storagResponse {JSON.stringify(storagResponse)}</p>
                            <p>fileTarget {JSON.stringify(fileTarget)}</p> */}
                        {/* <p>form {JSON.stringify(form)}</p> */}
                        {/* <p>ImgUrls {JSON.stringify(ImgUrls)}</p> */}
                        {/* <p>{JSON.stringify(categoryList)}</p> */}
                        <form onSubmit={handleSubmit} className={`row text-nowrap ${style.form}`}>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>標題</p>
                                    </div>
                                    <div className="col mt-2 ">
                                        <input
                                            type="text"
                                            className={`form-control ${style.formInput}`}
                                            placeholder='標題'
                                            name='title'
                                            value={title}
                                            onChange={handleChange}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>簡述</p>
                                    </div>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className={`form-control ${style.formInput}`}
                                            placeholder='簡述'
                                            name='description'
                                            value={description}
                                            onChange={handleChange}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p className=''> 分類 &nbsp;
                                            <i className="bi bi-pencil-square" onClick={() => { setPanel('EditCategories') }} />
                                        </p>
                                    </div>
                                    <div className="col">
                                        {/* <p>{JSON.stringify(categoryList[0])}</p> */}
                                        <select
                                            name='category'
                                            value={category}
                                            onChange={handleChange}
                                            className='catg-dropdown'>
                                            <option
                                                disabled
                                                value=""
                                                required={true}
                                            >
                                                選擇文章分類
                                            </option>
                                            {
                                                categoryList.map((option) => {
                                                    return (
                                                        <option value={option.categoryId} key={option.categoryId}>
                                                            {option.category}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-3 ">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>封面圖片</p>
                                    </div>
                                    <div className="col">
                                        {/* <p className='text-wrap'>coverImgUrl:{coverImgUrl}</p>  */}
                                        <input
                                            type="file"
                                            name="coverImgUrl"
                                            className={`form-control ${style.formInput}`}
                                            onChange={handleUpload}
                                        />
                                        {!isPending && coverImgUrl && (
                                            <div className={`${style.coverImgPreview} mt-2`}>
                                                <img
                                                    className='object-cover'
                                                    src={coverImgUrl}
                                                    alt=""
                                                />
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3 my-1">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>內文</p>
                                    </div>
                                    <div className="col-lg-10">
                                        <MarkdownEditor
                                            value={content}
                                            onChange={(value) => {
                                                setIsSave(false)
                                                setForm({ ...form, content: value })
                                            }}
                                            style={{ height: '150px' }}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>附件圖片</p>
                                    </div>
                                    <div className="col col-md-10">
                                        {/* <p>imgPreview:<br /><span className='text-wrap'>{imgPreview}</span></p>
                                        <p>ImgUrls({ImgUrls.length}):<br /><span className='text-wrap'>{JSON.stringify(ImgUrls)}</span></p> */}
                                        <input
                                            type="file"
                                            name="ImgUrls"
                                            className={`form-control ${style.formInput}`}
                                            onChange={handleUpload}
                                        />
                                        {ImgUrls.length !== 0 &&
                                            <div className="row my-2">
                                                <div className="col-8">
                                                    <div className={style.imgPreviewList}>
                                                        <ul >
                                                            {
                                                                ImgUrls.map((img, idx) => {
                                                                    return (
                                                                        <li
                                                                            key={idx}
                                                                            onClick={() => {

                                                                                setPanel('imgPreview')
                                                                                setImgPreview(img)
                                                                            }}
                                                                            className={img === imgPreview ? style.imgPreviewLiActive : ''}>
                                                                            {img}
                                                                        </li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={`col ${style.imgPreview}`}>
                                                    <button type='button' onClick={() => {
                                                        navigator.clipboard.writeText(imgPreview)
                                                            .then(() => {
                                                                toast.info('已複製網址');
                                                            })
                                                            .catch((err) => {
                                                                console.error('無法複製網址', err);
                                                            });
                                                    }}><i className="bi bi-copy"></i></button>
                                                    <img src={imgPreview} alt="圖片預覽" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>標籤</p>
                                    </div>
                                    <div className="col">
                                        <ReactTagInput
                                            tags={tags}
                                            placeholder='標籤'
                                            onChange={setTags}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="d-none d-md-block col-2">
                                        <p>相關連結</p>
                                        {/* {JSON.stringify(relatedLinks)} */}
                                    </div>
                                    <div className="col">
                                        <ObjectField
                                            fieldName={'displayText'}
                                            fieldNameHolder={'相關連結 顯示文字'}
                                            fieldValue={'url'}
                                            fieldValueHolder={'連結網址'}
                                            state={relatedLinks}
                                            setState={(data) => { setForm({ ...form, relatedLinks: data }) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row align-items-center">
                                    <div className="col-4 col-md-2">
                                        <p> 公開 </p>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='yes'
                                                name='isPublic'
                                                checked={isPublic === true}
                                                onChange={handleChange}
                                                id='isPublicYes'
                                                required
                                            />
                                            <label htmlFor="isPublicYes" className='form-check-label'>
                                                是 &nbsp;
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='no'
                                                name='isPublic'
                                                checked={isPublic === false}
                                                onChange={handleChange}
                                                id='isPublicNo'
                                                required
                                            />
                                            <label htmlFor="isPublicNo" className='form-check-label'>
                                                否 &nbsp;
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="row align-items-center">
                                    <div className="col-4 col-md-2">
                                        <p> 設為草稿 </p>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='yes'
                                                name='isDraft'
                                                checked={isDraft === true}
                                                onChange={handleChange}
                                                id='isDraftYes'
                                            />
                                            <label htmlFor="isDraftYes" className='form-check-label'>
                                                是 &nbsp;
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='no'
                                                name='isDraft'
                                                checked={isDraft === false}
                                                onChange={handleChange}
                                                id='isDraftNo'
                                            />
                                            <label htmlFor="isDraftNo" className='form-check-label'>
                                                否 &nbsp;
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="row align-items-center">
                                    <div className="col-4 col-md-2">
                                        <p> 顯示在首頁 </p>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='yes'
                                                name='displayOnHomePage'
                                                checked={displayOnHomePage === true}
                                                onChange={handleChange}
                                                id='displayOnHomePageYes'
                                                required
                                            />
                                            <label htmlFor="displayOnHomePageYes" className='form-check-label'>
                                                是 &nbsp;
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                value='no'
                                                name='displayOnHomePage'
                                                checked={displayOnHomePage === false}
                                                onChange={handleChange}
                                                id='displayOnHomePageNo'
                                                required
                                            />
                                            <label htmlFor="displayOnHomePageNo" className='form-check-label'>
                                                否 &nbsp;
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <div className="row">
                                    <div className="col-4 col-md-2">
                                        <p> 發布時間 </p>
                                    </div>
                                    <div className="col">
                                        <p>{formattedDate(createdAt, 'includeTime')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 col-md-2">
                                        <p> 最後修改 </p>
                                    </div>
                                    <div className="col">
                                        <p>{formattedDate(updatedAt, 'includeTime')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-1 d-flex flex-column position-fixed text-nowrap" style={{ top: "50%", right: "20px", zIndex: "99" }}>

                                <button
                                    className={`my-4 ${editMode === 'edit' && isSave === true ? "bg-warning text-dark" : "bg-danger text-light "}  d-flex justify-content-center align-items-center  text-nowrap`}
                                    type="submit"
                                    onClick={handleSubmit}
                                    style={{ minHeight: "50px", minWidth: "50px" }}
                                >
                                    <small>
                                        {editMode === 'edit' && isSave === true
                                            ? <><i className="bi bi-floppy"></i> <span className='d-none d-lg-inline-block'>&nbsp; 已存檔</span></>
                                            : <><i className="bi bi-floppy-fill"></i>
                                                <span className='d-none d-lg-inline-block'>&nbsp; 未保存</span></>}
                                    </small>
                                </button>
                                <button
                                    className={`${style.formBtn} mb-4 p-0`}
                                    type="button"
                                    style={{ minHeight: "50px", minWidth: "50px" }}
                                    onClick={handleCancel}
                                >
                                    <span>取消</span>
                                </button>
                                {editMode === 'edit' &&
                                    <button
                                        className={`${style.formBtn} p-0`}
                                        type="button"
                                        style={{ minHeight: "50px", minWidth: "50px" }}
                                        onClick={handleDelete}
                                    >
                                        <i className="bi bi-trash"></i>
                                        <span className='d-none d-lg-inline-block'>&nbsp; 刪除</span>
                                    </button>}
                            </div>

                            {panel === 'EditCategories' &&
                                <Panel setPanel={setPanel}>
                                    <EditCategories categories={categoryList} />
                                </Panel>
                            }

                        </form>
                    </div>

                </div>
            </div >
        </div >
    )
}
