// React related
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation, Link } from 'react-router-dom';
// Context
// Libraries
import MarkdownEditor from '@uiw/react-markdown-editor';
import { ToastContainer, toast } from 'react-toastify';
import { formattedDate } from '../../utility/index'
// Database or services
// Components
import ReactTagInput from '@pathofdev/react-tag-input';
import ObjectField from './ObjectField';
// Styles
import style from './_addEditRepo.module.scss'
import '@pathofdev/react-tag-input/build/index.css'
import './_customReactTags.scss'
// Hooks
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'
import { useDocument } from '../../hooks/useDocument';

const initialState = {
  repoTwName: "",
  repoEnName: "",
  description: "",
  githubUrl: "",
  deployUrls: [],
  coverImgUrl: "",
  tools: "",
  content: "",
  ImgUrls: [],
  relatedLinks: [],
  tags: [],
  isPublic: false,
  displayOnHomePage: false,
  createdAt: "",
  updatedAt: "",
}

export default function AddEditRepo({ setNavActive }) {
  const { id } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const pathSegments = location.pathname.split('/')[2];
  const [editMode, setEditMode] = useState('null')
  const { documentData } = useDocument('repos', id)

  const { addDocumentWithCustomID, deleteDocument, updateDocument, response } = useFirestore('repos')
  const { uploadFile, response: storagResponse, deleteFolder } = useStorage()

  const [form, setForm] = useState(initialState)  //定義表單內容
  const { repoTwName, repoEnName, githubUrl, deployUrls, description, coverImgUrl, tools, content, ImgUrls, relatedLinks, tags, isPublic, displayOnHomePage, createdAt, updatedAt } = form;

  // 上傳圖片與預覽
  const [isPending, setIsPending] = useState(false)
  const [fileTarget, setFileTarget] = useState('coverImgUrl')
  const [imgPreview, setImgPreview] = useState(null)

  const [isSave, setIsSave] = useState(true)

  //獲取文章ID和編輯模式
  useEffect(() => {
    setNavActive('repos')
    setEditMode(pathSegments)
    return () => { };
  }, [pathSegments]);

  useEffect(() => {
    if (editMode === 'edit' && documentData) {
      setForm({ ...documentData });
    }

    return () => { };
  }, [editMode, documentData]);

  // 表單變更
  const handleChange = (e) => {
    e.preventDefault();
    if (id) {
      setIsSave(false);
      const { name, value, type } = e.target;

      if (type === 'radio') {
        if (name === "isPublic") {
          const isPublicValue = value === 'yes';
          setForm({ ...form, isPublic: isPublicValue });
        } else if (name === "isDraft") {
          const isDraftValue = value === 'yes';
          setForm({ ...form, isDraft: isDraftValue });
        } else if (name === "displayOnHomePage") {
          const displayOnHomePageValue = value === 'yes';
          setForm({ ...form, displayOnHomePage: displayOnHomePageValue });
        }
      } else {
        if (name === "category") {
          setForm({ ...form, category: value });
        } else if (name === "repoEnName") {
          setForm({ ...form, repoEnName: value });
        } else {
          setForm({ ...form, [name]: value });
        }
      }
    }
  };

  // 上傳圖片
  const handleUpload = async (e) => {
    if (id) {
      setIsSave(false)
      const { name } = e.target;
      if (name === 'coverImgUrl') {
        setFileTarget('coverImgUrl')
      } else if (name === 'ImgUrls') {
        setFileTarget('ImgUrls')
      }
      await uploadFile(e.target.files[0], `repos/${id}/`)
    }
  }
  // 監聽上傳進度
  useEffect(() => {
    if (storagResponse.success) {
      setIsPending(true)
      if (fileTarget === 'coverImgUrl') {
        setForm({ ...form, coverImgUrl: storagResponse.file });
        setIsPending(false)
      } else if (fileTarget === 'ImgUrls') {
        const updatedImgUrls = [...ImgUrls, storagResponse.file];
        setForm({ ...form, ImgUrls: updatedImgUrls });
        setIsPending(false)
        if (ImgUrls.length === 0) { setImgPreview(storagResponse.file) }
      }
    }
  }, [storagResponse, isPending]);

  // 表單變更 標籤
  const setTags = (tags) => {
    setIsSave(false)
    setForm({ ...form, tags })
  }
  const handleToolsList = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setForm({ ...form, tools: "**Front-end Frameworks**\n\n - html / css / javascript\n\n**Back-end Frameworks**\n\n**Database**\n\n**Version Control**\n\n - git / gitHub\n\n**Package Management**\n\n - npm" });
    } else {
      setForm({ ...form, tools: "" });
    }
  };
  const handleGithubUrl = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setForm({ ...form, githubUrl: `https://github.com/wwwchendev/${repoEnName}` });
    } else {
      setForm({ ...form, githubUrl: "" });
    }
  }

  //確保值變化時表單會更新(建議依賴項不要放會經常變化的欄位)
  useEffect(() => {
    setForm(prevForm => ({ ...prevForm, coverImgUrl, ImgUrls, isPublic, displayOnHomePage }));
  }, [coverImgUrl, ImgUrls, isPublic, displayOnHomePage]);


  // 提交表單
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!id) { throw new Error('出現錯誤查無文章ID') }
    try {
      const fieldNames = {
        repoTwName: '中文名稱',
        repoEnName: '英文名稱',
        description: '簡述',
        githubUrl: 'github網址',
        coverImgUrl: '封面圖片',
        content: '內文',
        tools: '開發環境與工具',
        tags: '標籤'
      };
      const requiredFields = ['repoTwName', 'repoEnName', 'description', 'coverImgUrl', 'tools', 'tags'];
      const emptyFields = requiredFields.filter(field => !form[field]);
      if (emptyFields.length > 0) {
        const emptyFieldNames = emptyFields.map(field => fieldNames[field]);
        const errorMessage = `欄位未填寫: ${emptyFieldNames.join(', ')} `;
        toast.error(errorMessage);
      } else {
        if (editMode === 'create') {
          addDocumentWithCustomID(id, { ...form });
          toast.success('已新增文章')
          navigate(`/repos/edit/${id}`);
        } else if (editMode === 'edit') {
          updateDocument(id, { ...form })
          setIsSave(true)
          toast.success('已修改文章變更')
          navigate(`/repos/${id}`)
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  const handleCancel = (e) => {
    e.preventDefault();
    const confirmCancel = window.confirm('是否放棄本次變更')

    if (confirmCancel) {
      const destination = editMode === 'edit' ? `/repos/${id}` : `/repos`;
      navigate(destination);
    }
  }


  const handleDelete = (e) => {
    e.preventDefault();
    const cofirmDelete = window.confirm(`是否刪除數據庫紀錄 ${repoTwName}`)
    if (cofirmDelete) {
      deleteFolder(`repos/${id}/`)
      deleteDocument(id)
      toast.success(`已刪除數據庫紀錄 ${repoTwName} (${repoEnName})`)
      navigate(`/repos`)
    }
  }

  return (
    <div className="BelowNav">
      <ToastContainer autoClose={2000} position="top-center" />
      <div className="container py-5">
        <div className="row  justify-content-center align-items-center">
          <div className="text-center mb-3">
            <span className={`${style.title}`}> {editMode === 'create' ? '新增數據庫' : '編輯數據庫'}</span>
          </div>
          {/* {JSON.stringify(form)} */}
          <div className="col-12 col-md-10 col-lg-8">

            <form
              onSubmit={handleSubmit}
              className={`row text-nowrap ${style.form}`}>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>數據庫名稱 </p>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-12 col-md-6 mt-2 ">
                        <input
                          type="text"
                          className={`form-control ${style.formInput}`}
                          placeholder='中文名稱'
                          name='repoTwName'
                          value={repoTwName}
                          onChange={handleChange}
                          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                          required={true}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2 ">
                        <input
                          type="text"
                          className={`form-control ${style.formInput}`}
                          placeholder='英文名稱'
                          name='repoEnName'
                          value={repoEnName}
                          onChange={handleChange}
                          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>簡述</p>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-12  mt-2 ">
                        <input
                          type="text"
                          className={`form-control ${style.formInput}`}
                          placeholder='描述'
                          name='description'
                          value={description}
                          onChange={handleChange}
                          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2 d-flex ">
                    <p>
                      <input
                        type="checkbox"
                        id="githubCheckbox"
                        checked={form.githubUrl !== ""}
                        onChange={handleGithubUrl}
                      />Github網址
                      <Link to={`${githubUrl}`} target="_blank"><i className="bi bi-box-arrow-up-right ms-3 h3"></i></Link></p>
                  </div>
                  <div className="col">
                    <div className={`input-group ${style.inputGroup} `}>
                      <span className="input-group-text " id="basic-addon3">github.com/wwwchendev/</span>
                      <input type="text"
                        className="form-control"
                        id="basic-url"
                        value={(form.githubUrl !== "") ? repoEnName : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>上線網址</p>
                    {/* {JSON.stringify(deployUrls)} */}
                  </div>
                  <div className="col">
                    <ObjectField
                      fieldName={'displayText'}
                      fieldNameHolder={'上線網址 顯示文字'}
                      fieldValue={'url'}
                      fieldValueHolder={'部屬網址'}
                      state={deployUrls}
                      setState={(data) => { setForm({ ...form, deployUrls: data }) }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3 ">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>封面圖片</p>
                  </div>
                  <div className="col">
                    <input
                      type="file"
                      name="coverImgUrl"
                      className={`form-control ${style.formInput}`}
                      onChange={handleUpload}
                    />
                    {!isPending && coverImgUrl && (
                      <div className={`${style.coverImgPreview} mt-2`}>
                        <img
                          className='object-cover'
                          src={coverImgUrl}
                          alt=""
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div className="col-12 mb-3 my-1">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>
                      <input
                        type="checkbox"
                        id="toolsCheckbox"
                        checked={form.tools !== ""}
                        onChange={handleToolsList}
                      />
                      開發環境與工具
                    </p>
                  </div>
                  <div className="col-lg-10">
                    <MarkdownEditor
                      value={tools}
                      onChange={(value) => {
                        setIsSave(false)
                        setForm({ ...form, tools: value })
                      }}
                      style={{ height: '150px' }}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3 my-1">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>內文</p>
                  </div>
                  <div className="col-lg-10">
                    <MarkdownEditor
                      value={content}
                      onChange={(value) => {
                        setIsSave(false)
                        setForm({ ...form, content: value })
                      }}
                      style={{ height: '150px' }}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>附件圖片</p>
                  </div>
                  <div className="col col-md-10">
                    <input
                      type="file"
                      name="ImgUrls"
                      className={`form-control ${style.formInput}`}
                      onChange={handleUpload}
                    />
                    {ImgUrls.length !== 0 &&
                      <div className="row my-2">
                        <div className="col-8">
                          <div className={style.imgPreviewList}>
                            <ul >
                              {
                                ImgUrls.map((img, idx) => {
                                  return (
                                    <li
                                      key={idx}
                                      onClick={() => {
                                        // setPanel('imgPreview')
                                        setImgPreview(img)
                                      }}
                                      className={img === imgPreview ? style.imgPreviewLiActive : ''}>
                                      {img}
                                    </li>)
                                })
                              }
                            </ul>
                          </div>
                        </div>
                        <div className={`col ${style.imgPreview}`}>
                          <button type='button' onClick={() => {
                            navigator.clipboard.writeText(imgPreview)
                              .then(() => {
                                toast.info('已複製網址');
                              })
                              .catch((err) => {
                                console.error('無法複製網址', err);
                              });
                          }}><i className="bi bi-copy"></i></button>
                          <img src={imgPreview} alt="圖片預覽" />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>相關連結</p>
                    {/* {JSON.stringify(relatedLinks)} */}
                  </div>
                  <div className="col">
                    <ObjectField
                      fieldName={'displayText'}
                      fieldNameHolder={'相關連結 顯示文字'}
                      fieldValue={'url'}
                      fieldValueHolder={'連結網址'}
                      state={relatedLinks}
                      setState={(data) => { setForm({ ...form, relatedLinks: data }) }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="d-none d-md-block col-2">
                    <p>標籤</p>
                  </div>
                  <div className="col">
                    <ReactTagInput
                      tags={tags}
                      placeholder='標籤'
                      onChange={setTags}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row align-items-center">
                  <div className="col-4 col-md-2">
                    <p> 公開 </p>
                  </div>
                  <div className="col">
                    <div className="form-check form-check-inline">
                      <input
                        type='radio'
                        className='form-check-input'
                        value='yes'
                        name='isPublic'
                        checked={isPublic === true}
                        onChange={handleChange}
                        id='isPublicYes'
                        required
                      />
                      <label htmlFor="isPublicYes" className='form-check-label'>
                        是 &nbsp;
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type='radio'
                        className='form-check-input'
                        value='no'
                        name='isPublic'
                        checked={isPublic === false}
                        onChange={handleChange}
                        id='isPublicNo'
                        required
                      />
                      <label htmlFor="isPublicNo" className='form-check-label'>
                        否 &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="row align-items-center">
                  <div className="col-4 col-md-2">
                    <p> 顯示在首頁 </p>
                  </div>
                  <div className="col">
                    <div className="form-check form-check-inline">
                      <input
                        type='radio'
                        className='form-check-input'
                        value='yes'
                        name='displayOnHomePage'
                        checked={displayOnHomePage === true}
                        onChange={handleChange}
                        id='displayOnHomePageYes'
                        required
                      />
                      <label htmlFor="displayOnHomePageYes" className='form-check-label'>
                        是 &nbsp;
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type='radio'
                        className='form-check-input'
                        value='no'
                        name='displayOnHomePage'
                        checked={displayOnHomePage === false}
                        onChange={handleChange}
                        id='displayOnHomePageNo'
                        required
                      />
                      <label htmlFor="displayOnHomePageNo" className='form-check-label'>
                        否 &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-4 col-md-2">
                    <p> 發布時間 </p>
                  </div>
                  <div className="col">
                    <p>{formattedDate(createdAt, 'includeTime')}</p>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-4 col-md-2">
                    <p> 最後修改 </p>
                  </div>
                  <div className="col">
                    <p>{formattedDate(updatedAt, 'includeTime')}</p>
                  </div>
                </div>
              </div>


              <div className="col-1 d-flex flex-column position-fixed text-nowrap" style={{ top: "50%", right: "20px", zIndex: "99" }}>

                <button
                  className={`my-4 ${editMode === 'edit' && isSave === true ? "bg-warning text-dark" : "bg-danger text-light "}  d-flex justify-content-center align-items-center  text-nowrap`}
                  type="submit"
                  onClick={handleSubmit}
                  style={{ minHeight: "50px", minWidth: "50px" }}
                >
                  <small>
                    {editMode === 'edit' && isSave === true
                      ? <><i className="bi bi-floppy"></i> <span className='d-none d-lg-inline-block'>&nbsp; 已存檔</span></>
                      : <><i className="bi bi-floppy-fill"></i>
                        <span className='d-none d-lg-inline-block'>&nbsp; 未保存</span></>}
                  </small>
                </button>
                <button
                  className={`${style.formBtn} mb-4 p-0`}
                  type="button"
                  style={{ minHeight: "50px", minWidth: "50px" }}
                  onClick={handleCancel}
                >
                  <span>取消</span>
                </button>
                {editMode === 'edit' &&
                  <button
                    className={`${style.formBtn} p-0`}
                    type="button"
                    style={{ minHeight: "50px", minWidth: "50px" }}
                    onClick={handleDelete}
                  >
                    <i className="bi bi-trash"></i>
                    <span className='d-none d-lg-inline-block'>&nbsp; 刪除</span>
                  </button>}
              </div>
            </form>

          </div>
        </div>
      </div >
    </div >


  )
}
