import React, { useEffect } from 'react'
import style from './_Panel.module.scss'

export default function Panel({ setPanel, children }) {
    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === 'Escape') {
                setPanel('close')
            }
        };
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [setPanel]);

    return (
        <div className={style.container}>
            <div className={style.containerInner}>
                {children}
                <span className="formNote">按下 ESC鍵 關閉</span>
            </div>
            <button className={`${style.CloseBtn} formCloseBtn placeItemsCenter`} onClick={() => { setPanel('close') }} >
                <i className='bi bi-x-lg'></i>
            </button>
        </div>

    )
}
