import React, { useEffect, useState } from 'react';
import style from './_repos.module.scss'
import { excerpt, formattedDate } from '../../utility/index'
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom'
import { useCollection } from '../../hooks/useCollection'
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';

export default function Repos({ setNavActive, user }) {
    const navigate = useNavigate()
    //載入資料方法
    const { fetchDocument: fetchRepos, response: resRepos } = useCollection('repos')
    // 定義資料請求狀態
    const [data, setData] = useState([]) //來源資料
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const dataPerPage = 9;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);
    // 換頁的方法
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    // 判斷頁面
    const [pageType, setPageType] = useState('')
    const pathSegments = useLocation().pathname.split('/')[2]; //草稿
    const queryString = new URLSearchParams(useLocation().search)
    const searchQuery = queryString.get('q')
    //輸入框
    const [searchInput, setSearchInput] = useState('')
    useEffect(() => { setNavActive('repos') }, [])

    useEffect(() => {
        if (searchQuery && searchQuery !== '') {
            // console.log('搜尋');
            setPageType('search')
        } else if (!pathSegments) {
            // console.log('數據庫列表');
            setPageType('repos')
        } else if (pathSegments === 'private') {
            // console.log('草稿');
            setPageType('private')
        }
    }, [pathSegments, searchQuery])

    // 載入時請求資料

    // 1.頁面載入時會解析網址是否包含搜尋參數，有的話進行搜尋操作
    useEffect(() => {
        if (pageType === 'repos') {
            const unsubRepos = fetchRepos([
                ["isPublic", "==", true],
            ], [["createdAt", "desc"]])
            return () => { unsubRepos() };
        } else if (pageType === 'private') {
            const unsubRepos = fetchRepos([
                ["isPublic", "==", false],
            ], [["createdAt", "desc"]])
            return () => { unsubRepos() };
        } else if (pageType === 'search') {
            const unsubRepos = fetchRepos([
                ["isPublic", "==", true],
                ["tags", "array-contains", searchQuery],
            ], [["createdAt", "desc"]])
            return () => { unsubRepos() };
        }
    }, [pageType, searchQuery])

    //成功撈到資料之後設置為data
    useEffect(() => {
        if (resRepos && resRepos.success) {
            setData(resRepos.document || [])
        }
    }, [resRepos])

    // 產生隨機ID提供部落格編號
    function generateRandomId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    // 返回內容處理
    if (resRepos.success === null) { return (<Spinner />) }// 載入元件

    return (
        <div className={`BelowNav`}>
            <div
                className={style.hero}
                style={{
                    minHeight: "300px",
                    backgroundPosition: "55% 60%"
                }}
            ></div>
            <div className="container row justify-content-center">
                <div className="col-12 px-4">
                    <div className={style.titleGroup}>
                        <h3 ><Link to='/repos'>數據庫</Link></h3>
                        {user?.uid &&
                            <div className="d-flex ms-3">
                                <button onClick={() => {
                                    (pageType === 'repos')
                                        ? navigate(`/repos/private`)
                                        : navigate(`/repos`)
                                }}>
                                    {pageType === 'private' ? <i className="bi bi-lock"></i> : <i className="bi bi-unlock"></i>}
                                </button>
                                <button
                                    onClick={() => { navigate(`/repos/create/${generateRandomId(20)}`) }}
                                >
                                    <i className="bi bi-plus-lg"></i>
                                </button>
                            </div>
                        }
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className='px-3' style={{ fontSize: '2rem' }}>
                            {pageType === 'repos' && <>{`公開, 顯示 ${indexOfFirstData + 1} - ${Math.min(indexOfLastData, data.length)} 共 ${data?.length} 筆`}</>}
                            {pageType === 'search' && <> {`搜尋相關內容：${searchQuery},  共 ${data?.length} 筆`}
                            </>}
                            {pageType === 'private' && <>{`私人, 顯示 ${indexOfFirstData + 1} - ${Math.min(indexOfLastData, data.length)} 共 ${data?.length} 筆`}</>}
                        </p>
                        <div className={`${style.searchGroup} d-none d-lg-flex justify-content-end `}>
                            <form>
                                <input
                                    type="text"
                                    placeholder="搜尋關鍵字..."
                                    value={searchInput}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value)
                                    }}
                                />
                                <button type="submit" onClick={(e) => {
                                    e.preventDefault()
                                    navigate(`/repos?q=${searchInput}`)
                                    setSearchInput('')
                                }}>
                                    <i className='bi bi-search'></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <section className={`${style.tags} px-3 mb-2`}>
                        <i className="bi bi-tags-fill me-3" />
                        <Link to="/repos?q=練習" > <span className='me-3'> 練習 </span></Link>
                        <Link to="/repos?q=reviewed" ><span className='me-3'>reviewed</span></Link>
                    </section>
                    <main className={`row ${style.repoGroup}`}>
                        {currentData?.map((repo, idx) => (
                            <article className="col-12  col-md-6  col-lg-4 g-4 mb-3" key={repo.id}>
                                <Link to={`/repos/${repo.id}`} className={`${style.imgWrapper}`}>
                                    <img src={repo.coverImgUrl} alt={repo.repoTwName} />
                                    <div className={` ${style.repoContentGroup}`}>
                                        <h3>{repo.repoTwName}</h3>
                                        <span>{excerpt(`${repo.description}`, 23)}</span>
                                    </div>
                                    <span className={style.date}>{formattedDate(repo.createdAt)}</span>
                                </Link >
                            </article>
                        ))}

                        <div className="col-12 mt-5">
                            <Pagination
                                dataPerPage={dataPerPage}
                                totalData={data.length}
                                activePage={currentPage}
                                paginate={paginate}
                            />
                        </div>
                    </main>
                </div>
            </div>
        </div >
    )
}
