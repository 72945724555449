import { Link } from 'react-router-dom'
import style from './_footer.module.scss'

export default function Footer() {
    return (
        <footer className={`${style.footer} `}>
            <ul>
                <li>
                    <Link to="https://www.instagram.com/wwwchendev/" target='_blank'>
                        <i className="bi bi-instagram"></i>
                    </Link>
                </li>
                <li>
                    <Link to="https://github.com/wwwchendev" target='_blank'>
                        <i className="bi bi-github"></i>
                    </Link>
                </li>
                <li>
                    <Link to="https://codepen.io/wwwchendev" target='_blank'>
                        <i className="bi bi-code-square"></i>
                    </Link>
                </li>
                <li>
                    <Link to="https://www.pinterest.com/wwwchendev/" target='_blank'>
                        <i className="bi bi-pinterest"></i>
                    </Link>
                </li>
            </ul>
            <Link to="/auth" className="text-center">
                &copy; 2023 wwwchendev. 版權所有
            </Link>
        </footer>

    )
}