// React related
import { createContext, useEffect, useState } from 'react'
// Context
// Libraries
// Database or services
// Components
// Pages
// Hooks
import { useCollection } from '../hooks/useCollection'
// Styles

export const CategoryContext = createContext()


export const CategoryContextProvider = ({ children }) => {

  const [categoryData, setCategoryData] = useState([])
  const [blogsData, setBlogsData] = useState([])
  const [categoryList, setCategoryList] = useState([])

  const { fetchDocument: fetchBlogCategories, response: resCategories } = useCollection('blogCategories')
  const { fetchDocument: fetchBlogs, response: resBlogs } = useCollection('blogs', [
    ["isDraft", "==", false],
    ["isPublic", "==", true]
  ])

  // 啟用監聽
  useEffect(() => {
    const unsubBlogCategories = fetchBlogCategories()
    const unsubBlogs = fetchBlogs()
    return () => {
      unsubBlogCategories(); // 組件卸載時取消監聽
      unsubBlogs()
    };
  }, [])

  // 當 resCategories 更新時更新 categoryData
  useEffect(() => {
    if (resCategories && resBlogs) {
      setCategoryData(resCategories.document || [])
      setBlogsData(resBlogs.document || [])
    }
  }, [resCategories, resBlogs])

  // 統計分類
  useEffect(() => {
    if (blogsData && categoryData) {
      // 計算每個類別的文章數量
      const categoryIdCounts = {};
      blogsData.forEach(blog => {
        if (!blog.isDraft && blog.isPublic) {
          const category = blog.category;
          if (categoryIdCounts[category]) {
            categoryIdCounts[category]++;
          } else {
            categoryIdCounts[category] = 1;
          }
        }
      });
      // console.log(JSON.stringify(categoryIdCounts));
      //>> categoryCounts {"6UMNc446VEeFwJw7AIPf":2,"BzV2uKDdszP3WIeUsX0Q":1}

      // 以類別和文章數量的形式建立結果陣列
      const categoryStatistics = categoryData.map(categoryData => {
        return {
          category: categoryData.category,
          categoryId: categoryData.id,
          count: categoryIdCounts[categoryData.id] || 0
        };
      })
      // .filter(statistic => statistic.count !== 0);
      // console.log(JSON.stringify(categoryStatistics));
      //>> [{"category":"程式","categoryId":"6UMNc446VEeFwJw7AIPf","count":2},{"category":"旅遊","categoryId":"BzV2uKDdszP3WIeUsX0Q","count":1}]
      setCategoryList(categoryStatistics);
    }
  }, [blogsData, categoryData])

  // 用名字找ID
  const getCategoryID = (categoryName) => {
    const matchedCategory = categoryList.find(item => item.category === categoryName);
    const categoryId = matchedCategory ? matchedCategory.categoryId : null;
    return categoryId
  }

  // 用ID找名字
  const getCategoryName = (categoryID) => {
    const matchedCategory = categoryList.find(item => item.categoryId === categoryID);
    const categoryName = matchedCategory ? matchedCategory.category : null;
    return categoryName
  }

  return (
    <CategoryContext.Provider value={{ categoryList, getCategoryID, getCategoryName }}>
      {children}
    </CategoryContext.Provider>
  )
}





