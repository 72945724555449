import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router';
import style from './_blogs.module.scss'
import { excerpt, formattedDate } from '../../utility/index'
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import Category from './Category';
import { useCollection } from '../../hooks/useCollection'
import { useCategoryContext } from '../../hooks/useCategoryContext';

export default function Blogs({ setNavActive, user }) {
    const navigate = useNavigate()
    const { getCategoryName } = useCategoryContext() //分類表 

    //載入資料方法
    const { fetchDocument: fetchBlogs, response: resBlogs } = useCollection('blogs')

    // 判斷頁面
    const [pageType, setPageType] = useState('')
    const pathSegments = useLocation().pathname.split('/')[2]; //草稿
    const queryString = new URLSearchParams(useLocation().search)
    const searchQuery = queryString.get('q')

    // 定義資料請求狀態
    const [data, setData] = useState([]) //來源資料

    //輸入框
    const [searchInput, setSearchInput] = useState('')

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const dataPerPage = 10;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);
    // 換頁的方法
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    // 初始化頁面
    useEffect(() => { setNavActive('blogs') }, [])

    useEffect(() => {
        if (searchQuery && searchQuery !== '') {
            // console.log('搜尋');
            setPageType('search')
        } else if (!pathSegments) {
            // console.log('文章列表');
            setPageType('blogs')
        } else if (pathSegments === 'drafts') {
            // console.log('草稿');
            setPageType('drafts')
        } else if (pathSegments === 'private') {
            // console.log('草稿');
            setPageType('private')
        }
    }, [pathSegments, searchQuery])


    // 1.頁面載入時會解析網址是否包含搜尋參數，有的話進行搜尋操作
    useEffect(() => {

        if (pageType === 'blogs') {
            const unsubBlogs = fetchBlogs([
                ["isDraft", "==", false],
                ["isPublic", "==", true],
            ], [["createdAt", "desc"]])

            return () => {
                unsubBlogs();
            };
        } else if (pageType === 'drafts') {
            const unsubBlogs = fetchBlogs([
                ["isDraft", "==", true],
            ], [["createdAt", "desc"]])
            return () => {
                unsubBlogs();
            };
        } else if (pageType === 'private') {
            const unsubBlogs = fetchBlogs([
                ["isPublic", "==", false],
            ], [["createdAt", "desc"]])
            return () => {
                unsubBlogs();
            };
        } else if (pageType === 'search') {
            const unsubBlogs = fetchBlogs([
                ["isDraft", "==", false],
                ["isPublic", "==", true],
                ["tags", "array-contains", searchQuery],
            ])
            return () => {
                unsubBlogs();
            };
        }
    }, [pageType, searchQuery])


    //成功撈到資料之後設置為data
    useEffect(() => {
        if (resBlogs && resBlogs.success) {
            setData(resBlogs.document)
        }
    }, [resBlogs, pathSegments, pageType, searchQuery])



    // 產生隨機ID提供部落格編號
    function generateRandomId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // 返回內容處理
    if (resBlogs.success === null) { return (<Spinner />) }// 載入元件
    return (
        <div className={`BelowNav`}>
            <div
                className={style.hero}
                style={{ minHeight: "300px", backgroundPosition: "55% 60%" }}
            />
            <div className="container row justify-content-center">
                <div className="col-12 px-4">
                    <div className="row justify-content-between g-5">

                        {/* 主要區塊 */}
                        <main className="col-12 col-md-8">
                            {/* 文章列表 */}
                            <section className={style.articlesGroup}>
                                <div className={style.titleGroup}>
                                    {pageType === 'blogs' && <h3 style={{ fontSize: '2rem' }}>{`文章列表, 顯示 ${indexOfFirstData + 1} - ${Math.min(indexOfLastData, data.length)} 共 ${data?.length} 筆`}</h3>}
                                    {pageType === 'search' && <h3 style={{ fontSize: '2rem' }}>搜尋相關內容：{searchQuery} ({data.length})</h3>}
                                    {pageType === 'drafts' && <h3 style={{ fontSize: '2rem' }}>{`草稿匣, 顯示 ${indexOfFirstData + 1} - ${Math.min(indexOfLastData, data.length)} 共 ${data?.length} 筆`}</h3>}

                                    {pageType === 'private' && <h3 style={{ fontSize: '2rem' }}>{`文章列表(不公開), 顯示 ${indexOfFirstData + 1} - ${Math.min(indexOfLastData, data.length)} 共 ${data?.length} 筆`}</h3>}
                                    {user?.uid &&
                                        <div className="d-flex">
                                            <button onClick={() => {
                                                (pageType === 'blogs')
                                                    ? navigate(`/blogs/private`)
                                                    : navigate(`/blogs`)
                                            }}>
                                                {pageType === 'private' ? <i className="bi bi-lock"></i> : <i className="bi bi-unlock"></i>}
                                            </button>
                                            <button onClick={() => { navigate(`/blogs/drafts`) }}><i className="bi bi-archive"></i></button>
                                            <button onClick={() => { navigate(`/blogs/create/${generateRandomId(20)}`) }}><i className="bi bi-plus-lg"></i></button>
                                        </div>
                                    }
                                </div>
                                {/* <p>{JSON.stringify(pageType)}</p>
                                <p>{JSON.stringify(resBlogs)}</p>
                                <p>{JSON.stringify(data)}</p> */}

                                {currentData?.map((blog, idx) => (
                                    <Link to={`/blogs/${blog.id}`} key={blog.id}>
                                        <article className='mb-5'>
                                            <div className={style.articleImgWrapper}>
                                                <img src={blog.coverImgUrl} alt={blog.title} />
                                            </div>

                                            <div className={style.articleDataWrapper}>
                                                <div className="d-flex align-items-center">
                                                    <span>{formattedDate(blog.createdAt)}</span>
                                                    <span className={style.articleDataSpacer} />
                                                    <span>{getCategoryName(blog.category)}</span>
                                                </div>

                                                <h4 className={style.title}>{excerpt(blog.title, 30)}
                                                </h4>
                                                <p>{excerpt(`${blog.description}`, 20)}</p>
                                            </div>

                                            {user?.uid &&
                                                <div className={style.tags}>

                                                    {
                                                        blog.isPublic === true
                                                            ? ''
                                                            : <p className={`${style.tag} ${style.private}`}>
                                                                未公開
                                                            </p>
                                                    }
                                                    {
                                                        blog.isDraft !== true
                                                            ? ''
                                                            : <p className={`${style.tag} ${style.draft}`}>
                                                                草稿
                                                            </p>
                                                    }

                                                </div>
                                            }
                                        </article>
                                    </Link>
                                ))
                                }
                            </section>
                            {/* <p>currentPage{currentPage}</p>
                            <p>blogCounts{blogCounts}</p>
                            <p>pageTotal{pageTotal}</p> */}

                            <Pagination
                                dataPerPage={dataPerPage}
                                totalData={data.length}
                                activePage={currentPage}
                                paginate={paginate}
                            />
                        </main>

                        {/* 🚧側欄工具 */}
                        <aside className="col-12 col-md-4">

                            <div className={`${style.searchGroup} d-none d-md-block`}>
                                <div className={style.titleGroup}>
                                    <h3>搜尋</h3>
                                </div>

                                <form className={`my-5`}>
                                    <input
                                        type="text"
                                        placeholder="搜尋關鍵字..."
                                        value={searchInput}
                                        onChange={(e) => {
                                            setSearchInput(e.target.value)
                                        }}
                                    />
                                    <button type="submit" onClick={(e) => {
                                        e.preventDefault()
                                        // console.log(e.target.value);
                                        navigate(`/blogs?q=${searchInput}`)
                                        setSearchInput('')
                                    }}>
                                        <i className='bi bi-search'></i>
                                    </button>
                                </form>

                            </div>

                            {/*🚧 文章分類 */}
                            <div className="d-block">
                                <div className={style.titleGroup}>
                                    <h3>分類</h3>
                                </div>
                                <Category />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    )
}
