
import React, { useState, useEffect, useRef } from 'react';
import style from './_home.module.scss'
import { useAuthContext } from "../../hooks/useAuthContext"
import { useFirestore } from '../../hooks/useFirestore'
import { useCollection } from '../../hooks/useCollection'

const Headline = () => {
  const { user } = useAuthContext()
  const [date, setDate] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  const [blogConfig, setBlogConfig] = useState('');
  const [editIntro, setEditIntro] = useState(false);


  const { fetchDocument: fetchBlogConfig, response: resBlogConfig } = useCollection('blogConfig')
  const { updateDocument, response } = useFirestore('blogConfig')

  useEffect(() => {
    const unsubBlogConfig = fetchBlogConfig();
    return () => {
      unsubBlogConfig();
    };
  }, []);

  //成功撈到資料之後設置為data
  useEffect(() => {
    if (resBlogConfig && resBlogConfig.success) {
      setBlogConfig(resBlogConfig?.document[0] || [])
    }
  }, [resBlogConfig])

  function getTime(type) {
    const time = new Date()
    // 獲取年、月、日
    const year = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, '0');
    const day = String(time.getDate()).padStart(2, '0');

    // 獲取星期幾的名稱
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = daysOfWeek[time.getDay()];

    // 獲取小時、分鐘
    const hour = String(time.getHours()).padStart(2, '0');
    const minute = String(time.getMinutes()).padStart(2, '0');
    const second = String(time.getSeconds()).padStart(2, '0');
    const ampm = hour >= 12 ? 'pm' : 'am';

    // 將時間轉換為12小時制
    const formattedHour = hour % 12 || 12;

    // 構建最終格式的日期和時間
    const formattedDate = `${year}/${month}/${day} ${dayOfWeek}`;
    const formattedTime = `${hour}:${minute}:${second} `;
    if (type === 'date') {
      return formattedDate;
    } else if (type === 'time') {
      return `${formattedDate} ${formattedTime}`;
    }
    return formattedDate;
  }

  useEffect(() => {
    setDate(getTime('date'));
    setCurrentTime(getTime('time')); // 初始时间

    const intervalId = setInterval(() => {
      setCurrentTime(getTime('time'));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="col-12 " style={{ height: '60px' }} >
      <div className={`${style.headline}`}>
        <h3 className={`${style.fancyBorder}`}>
          <span className='d-lg-none text-nowrap px-2'> {date}</span>
          <span className='d-none d-lg-flex '>{currentTime} </span>
        </h3>
        <input
          type="text"
          id="introInput"
          className={`nowrap py-3 ${editIntro ? 'border' : ''}`}
          value={(blogConfig?.intro || '')}
          onChange={(e) => {
            setBlogConfig((pre) => ({ ...pre, intro: e.target.value }));
            window.addEventListener('keyup', (event) => {
              if (event.key === 'Enter') {
                setEditIntro((pre) => !pre)
                updateDocument(blogConfig.id, { intro: blogConfig.intro });
              }
            });
          }}
          disabled={editIntro !== true}
        />
        {user && <button className='d-none d-md-block ms-auto me-4'
          onClick={() => {
            setEditIntro((pre) => !pre)
            updateDocument(blogConfig.id, { intro: blogConfig.intro });
          }}> <i className="bi bi-pencil-square" /></button>}
      </div>
    </div>
  )
}

export default Headline