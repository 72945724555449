// React related
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// Context
// Libraries
import { toast } from 'react-toastify';
// Database or services
import { auth } from '../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
// Styles
// Components
// Pages
// Hooks

export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  // 檢查組件是否被卸載
  const [isCancelled, setIsCancelled] = useState(false)
  const navigate = useNavigate()

  const signup = async (email, password, displayName) => {
    setError(null)
    setIsPending(true)

    try {
      //i 使用 createUserWithEmailAndPassword 方法註冊新使用者
      // 此方法接受使用者的電子郵件地址和密碼作為參數，並傳回一個 Promise。在 Promise 的成功回呼中，你可以存取使用者憑證 (userCredential)，其中包含新建立使用者的信息，例如使用者 ID (user.uid)
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      // console.log(`userCredential`, userCredential);
      /* 
      >> UserCredentialImpl 
      {
          "user": {
            "uid": "p6JfeNdAiDeYkZ9t80B12tbKxK43",
            "email": "test03@gmail.com",
            "emailVerified": false,
            "displayName": "03",
            "isAnonymous": false,
            "providerData": [ {...} ],
            "stsTokenManager": {...},
            "createdAt": "1703385373644",
            "lastLoginAt": "1703385373644",
            "apiKey": "...",
            "appName": "[DEFAULT]"
        },
        "providerId": null,
        "_tokenResponse": {..},
        "operationType": "signIn"
      }
    */

      if (!userCredential) {
        throw new Error('無法完成註冊')
      }

      //i 使用 user.updateProfile更新已驗證用戶信息
      // user.updateProfile 是 Firebase Authentication 提供的一個方法，用於更新已經通過身份驗證的用戶的個人資料信息，例如修改用戶的顯示名稱（displayName）和頭像（photoURL）等。
      await updateProfile(userCredential.user, { displayName: displayName })//為使用者新增顯示名稱 

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
      toast.success(`註冊完成！${userCredential.user.displayName}`)
      navigate('/auth')
    }
    catch (err) {
      if (!isCancelled) {
        console.log(err.message)
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    //這個返回的函數會在組件卸載時執行
    return () => {
      setIsCancelled(true)
    };
  }, [])


  return { signup, error, isPending }
}