// React related
import { useEffect, useState } from 'react'
// Context
import { useAuthContext } from './useAuthContext'
// Libraries
// Database or services
import { auth } from '../firebase/config'
// Styles
// Components
// Pages
// Hooks

export const useLogout = () => {
  // TODO　處理組件卸載或清除時的操作
  // - 錯誤說明：
  // 當觸發 auth.signOut() 等異步請求後，如果撰寫了像 setIsPending(false) 或 setError(null) 這樣的 useState 的 setter 函數來更新狀態，並且在組件卸載(比方頁面切換)後這些異步操作仍在進行，可能導致嘗試更新已卸載組件的狀態，引發類似 "Can't perform a React state update on an unmounted component" 的錯誤。
  // - 處理方式:
  // 使用 isCancelled 狀態來追蹤組件是否已卸載。這個狀態初始化為 false，表示組件一開始是未卸載的狀態。透過 useEffect 中的清理函數，在組件卸載時設置 isCancelled 為 true。在後續的程式碼執行時，應該檢查 isCancelled 狀態，只有在組件未卸載時才執行狀態的更新。

  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  // 檢查組件是否被卸載
  const [isCancelled, setIsCancelled] = useState(false)

  // 調用hooks得到useContext(AuthContext)的結果= AuthContext.Provider 提供的值
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setError(null)
    setIsPending(true)

    try {
      // sign the user out
      await auth.signOut()
      dispatch({ type: 'LOGOUT' })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    }
    catch (err) {
      if (!isCancelled) {
        console.log(err.message)
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    //這個返回的函數會在組件卸載時執行
    return () => { 
      setIsCancelled(true) 
    };
  }, [])

  return { logout, error, isPending }
}