import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import style from './_blog.module.scss'
import { useDocument } from '../../hooks/useDocument'
import { formattedDate } from '../../utility/index'
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useCategoryContext } from '../../hooks/useCategoryContext';
import Spinner from '../../components/spinner/Spinner';

export default function Blog({ setNavActive, user }) {
    const { id } = useParams();
    const { documentData } = useDocument('blogs', id)
    const { getCategoryName } = useCategoryContext() //分類表
    const navigate = useNavigate()

    useEffect(() => {
        setNavActive('blogs')
    }, [setNavActive])

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cpwebassets.codepen.io/assets/embed/ei.js';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            // Cleanup script when component unmounts
            document.head.removeChild(script);
        };
    }, []);


    if (documentData === null) { return (<Spinner />) }// 載入元件
    return (
        <div className={`BelowNav`}>
            <div className={style.coverGroup}>
                <div
                    className={style.cover}
                    style={{
                        maxHeight: "300px",
                        backgroundImage: `url(${documentData?.coverImgUrl})`
                    }}
                />
            </div>
            <div className="container row justify-content-center">
                <div className={`col-11 col-lg-8 ${style.main}`}>
                    <main>

                        <div className={style.coverTitleGroup}>
                            <div className="d-flex align-items-center">
                                <span>{formattedDate(documentData?.createdAt)}</span>
                                <span className={style.articleDataSpacer} />
                                <Link to={`/blogs/category/${getCategoryName(documentData?.category)}`}>
                                    <span>{getCategoryName(documentData?.category)}</span>
                                </Link>
                            </div>
                            <div className="d-flex">
                                <h2 className='mb-0' style={{ fontSize: "3rem" }}>{documentData?.title}</h2>
                                {user?.uid && <button onClick={(e) => {
                                    e.preventDefault()
                                    navigate(`/blogs/edit/${id}`)
                                }}>
                                    <i className="bi bi-pencil-square mx-4" />
                                </button>}
                            </div>
                        </div>


                        {/* <span className='text-wrap'>
                            {JSON.stringify(documentData.content)}
                        </span> */}
                        <MarkdownEditor.Markdown
                            source={documentData?.content}
                            className={style.markdown}
                            style={{
                                background: "transparent",
                            }}
                        />

                    </main>
                    <div className="mb-5">
                        <h3 className='text-nowrap mb-3' style={{ fontSize: "2.2rem" }}>相關連結</h3>
                        {documentData?.relatedLinks.map((e, idx) => {
                            return (
                                <li className='mb-4' key={idx}>
                                    <Link to={e.url} target="_blank">
                                        {e.displayText}
                                    </Link>
                                </li>
                            )
                        })}
                    </div>
                    <hr className='mb-5' />
                    <div
                        className={`d-flex align-items-center justify-content-center flex-wrap ${style.tags}`}>
                        <i className="bi bi-tags-fill me-3" />
                        {documentData?.tags.map((tag, idx) => {
                            return (
                                <Link to={`/blogs?q=${tag}`} key={idx}>
                                    <span className='me-3' >
                                        {tag}
                                    </span>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
