import React, { useEffect, useRef, useState } from 'react'
import style from './_addEditRepo.module.scss'

export default function ObjectField({ fieldName, fieldNameHolder, fieldValue, fieldValueHolder, state, setState }) {
  const [array, setArray] = useState([{ [fieldName]: '', [fieldValue]: '' }])

  //讀取資料
  // 這裡的 useRef 創建了一個保存上一個 state 值的變數 prevState。
  // 在 useEffect 中，它會比較 prevState.current 和 state 是否相同，如果不同則更新 prevState，並設置 array。這樣可以避免無窮迴圈的問題。
  const prevState = useRef(state)
  useEffect(() => {
    if (prevState.current !== state) {
      prevState.current = state;
      if (state.length > 0) {
        setArray(state)
      }
    }
  }, [state])

  //如果array變了更新回去表單
  useEffect(() => {
    setState(array)
  }, [array])

  //控制欄位增減+值的變更
  const handleChange = (e, idx) => {
    const { name, value } = e.target
    const newArray = [...array]
    if (name === 'fieldName') {
      newArray[idx][fieldName] = value
    } else if (name === 'fieldValue') {
      newArray[idx][fieldValue] = value
    }
    setArray(newArray)
  }

  const addField = () => {
    const newArray = [...array, { [fieldName]: '', [fieldValue]: '' }]
    setArray(newArray)
  }
  const removeField = (idx) => {
    const newArray = [...array.slice(0, idx), ...array.slice(idx + 1)]
    setArray(newArray)
  }

  return (<>
    {
      array.map((ary, idx) => {
        return (
          <div className="row align-items-center mb-3" key={idx}>
            <div className="col-4">
              <input
                type="text"
                className={`form-control ${style.formInput}`}
                placeholder={fieldNameHolder}
                name='fieldName'
                value={ary[fieldName]}
                onChange={(e) => { handleChange(e, idx) }}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className={`form-control ${style.formInput}`}
                placeholder={fieldValueHolder}
                name='fieldValue'
                value={ary[fieldValue]}
                onChange={(e) => { handleChange(e, idx) }}
              />
            </div>
            <div className="col-1">
              <button type='button' onClick={addField}>
                <i className="bi bi-plus-lg"></i>
              </button>
              {array.length > 1 &&
                <button
                  type='button'
                  onClick={() => { removeField(idx) }}
                  disabled={array.length === 1}
                >
                  <i className="bi bi-x-lg"></i>
                </button>}
            </div>
          </div>
        )
      })
    }
  </>
  )
}
