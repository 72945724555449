// React related
import { useContext } from "react";
// Context
import { CategoryContext } from "../context/CategoryContext";
// Libraries
// Database or services
// Styles
// Components
// Pages
// Hooks

export const useCategoryContext = () => {
    const context = useContext(CategoryContext)
    if (!context) {
        throw Error('useCategoryContext必須在CategoryContextProvider內使用')
    }
    // console.log(context);
    return context
}