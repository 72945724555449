export const excerpt = (str, count = 30) => {
    if (str.length > count) {
        str = str.substring(0, count) + '...'
    }
    return str
}

export const formattedDate = (timestamp, includeTime) => {
    if (timestamp) {
        const dateObject = timestamp.toDate();
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');

        let formattedDate = `${year}/${month}/${day}`;

        if (includeTime) {
            const hour = String(dateObject.getHours()).padStart(2, '0');
            const minute = String(dateObject.getMinutes()).padStart(2, '0');
            const second = String(dateObject.getSeconds()).padStart(2, '0');

            formattedDate += ` ${hour}:${minute}:${second}`;
        }

        return formattedDate;
    }
};
