// React related
import { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, Routes, Route, Navigate } from 'react-router-dom'
// Context
import { CategoryContextProvider } from './context/CategoryContext';
// Libraries
import { ToastContainer, toast } from 'react-toastify'; //react-toastify
import 'bootstrap/dist/js/bootstrap.bundle'; //用於提供BS動畫和過渡
// Database or services
// Components
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
// Pages
import Home from './pages/home/Home';
import Blog from './pages/blog/Blog';
import Blogs from './pages/blogs/Blogs';
import Repo from './pages/repo/Repo';
import Repos from './pages/repos/Repos';
import Auth from './pages/auth/Auth';
import AddEditBlog from './pages/addEditBlog/AddEditBlog';
import CategoryBlog from './pages/categoryBlog/CategoryBlog';
import AddEditRepo from './pages/addEditRepo/AddEditRepo';
// Styles
import './stylesheets/main.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss' //icons
import 'react-toastify/dist/ReactToastify.css'
// Hooks
import { useAuthContext } from './hooks/useAuthContext';
function App() {
  const { authIsReady, user } = useAuthContext()
  const [theme, setTheme] = useState('dark')
  const [navActive, setNavActive] = useState('home')
  useEffect(() => {
    // toast.info('test')
  }, [])

  return (
    <div className={`App ${theme}`}>
      {authIsReady && (
        <>
          <Navbar navActive={navActive} theme={theme} setTheme={setTheme} />
          <ScrollToTop />
          <ToastContainer autoClose={2000} position="top-center" />

          <Routes>

            <Route path="/" element={
              <CategoryContextProvider>
                <Home navActive={navActive} setNavActive={setNavActive} />
              </CategoryContextProvider>} />
            <Route path="/blogs" >
              <Route index
                element={
                  <CategoryContextProvider>
                    <Blogs setNavActive={setNavActive} user={user} />
                  </CategoryContextProvider>
                }
              />
              <Route path='drafts'
                element={user?.uid ? <CategoryContextProvider><Blogs setNavActive={setNavActive} user={user} /></CategoryContextProvider> : <Navigate to="/blogs" />}
              />
              <Route path='private'
                element={user?.uid ? <CategoryContextProvider><Blogs setNavActive={setNavActive} user={user} /></CategoryContextProvider> : <Navigate to="/blogs" />}
              />
              <Route path='create/:id' element={user?.uid ? <CategoryContextProvider><AddEditBlog setNavActive={setNavActive} /></CategoryContextProvider> : <Navigate to="/" />} />
              <Route path='edit/:id' element={user?.uid
                ? <CategoryContextProvider><AddEditBlog setNavActive={setNavActive} /></CategoryContextProvider>
                : <Navigate to="/" />} />
              <Route path=':id' element={<CategoryContextProvider><Blog setNavActive={setNavActive} user={user} /></CategoryContextProvider>} />
              <Route
                path='category/:category'
                element={
                  <CategoryContextProvider>
                    <CategoryBlog setNavActive={setNavActive} />
                  </CategoryContextProvider>
                } />
              <Route path='tag/:tag' element={<CategoryBlog setNavActive={setNavActive} />} />
            </Route>

            <Route path="/repos" >
              <Route index element={<Repos user={user} setNavActive={setNavActive} />} />
              <Route path=':id' element={<Repo user={user} setNavActive={setNavActive} />} />
              <Route path='create/:id' element={user?.uid ? <AddEditRepo setNavActive={setNavActive} /> : <Navigate to="/repos" />} />
              <Route path='edit/:id'
                element={user?.uid
                  ? <AddEditRepo setNavActive={setNavActive} />
                  : <Navigate to="/repos" />} />
              <Route path='private'
                element={user?.uid ? <Repos setNavActive={setNavActive} user={user} /> : <Navigate to="/repos" />}
              />
            </Route>
            <Route path="/auth" element={<Auth setNavActive={setNavActive} />} />

          </Routes>


          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
