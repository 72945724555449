// React related
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom'
// Context
// Libraries
import MarkdownEditor from '@uiw/react-markdown-editor';
import { excerpt, formattedDate } from '../../utility/index'
// Database or services
// Components
import Panel from '../../components/panel/Panel';
import Spinner from '../../components/spinner/Spinner';
// Styles
import style from './_repo.module.scss'
// Hooks
import { useDocument } from '../../hooks/useDocument';
// Swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

// ImageGallery
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './_imageGallery.scss'

export default function Repo({ setNavActive, user }) {
  const navigate = useNavigate()
  const { id } = useParams();
  const { documentData } = useDocument('repos', id)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const swiperRef = useRef();
  //頁面初始化
  useEffect(() => {
    setNavActive('repos')
  }, [])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setSelectedImageIndex(null)
      }
    };

    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  if (documentData === null) { return (<Spinner />) }// 載入元件

  const { repoTwName, repoEnName, githubUrl, deployUrls, description, coverImgUrl, tools, content, ImgUrls, relatedLinks, tags, isPublic, createdAt, updatedAt } = documentData;

  const handleSlideChange = (index) => {
    setSelectedImageIndex(index);
  };




  return (
    <div className={`BelowNav`}>
      <div className="container row justify-content-center">
        <div className={`col-11 col-lg-8 ${style.main}`}>
          {/* <p>{JSON.stringify(documentData.githubUrl)}</p> */}
          <main>
            <div className={style.coverTitleGroup}>
              <div className="d-flex">
                <h2>{repoTwName}</h2>
                {user?.uid &&
                  <button onClick={(e) => {
                    e.preventDefault()
                    navigate(`/repos/edit/${id}`)
                  }}>
                    <i className="bi bi-pencil-square mx-4" />
                  </button>}
              </div>
              <span className='mb-3' style={{ opacity: "0.5" }}>
                {repoEnName}
              </span>
              <p className='text-center'>{description}</p>
            </div>

            <div className="row mb-3">


              <div className={`${style.swiper}`}>
                <div className={` ${style.swiperSlide}`}>
                  <button
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <i className="bi bi-caret-left-fill"></i>
                  </button>
                  <button onClick={() => swiperRef.current?.slideNext()}>
                    <i className="bi bi-caret-right-fill"></i>
                  </button>
                </div>
                <Swiper
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  spaceBetween={20}
                  breakpoints={{
                    576: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                  }}
                >
                  {
                    ImgUrls.length === 0
                      ? <SwiperSlide
                        // onClick={() => {
                        //   handleSlideChange(0);
                        // }}
                        style={{ cursor: 'pointer' }}
                      >
                        <img src={coverImgUrl} alt={repoTwName} />
                      </SwiperSlide>
                      : ImgUrls.map((img, idx) => (
                        <SwiperSlide
                          key={idx}
                          onClick={() => {
                            handleSlideChange(idx);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <img src={img} alt={img} />
                        </SwiperSlide>
                      ))
                  }




                </Swiper>
              </div>
            </div>
            {selectedImageIndex !== null && (
              <>
                <Panel setPanel={() => { setSelectedImageIndex(null) }}>
                  <ImageGallery
                    items={ImgUrls.map((img, idx) => ({
                      original: img,
                      thumbnail: img,
                      description: `${idx + 1}`,
                    }))}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    slideDuration={500}
                    thumbnailPosition='bottom'
                    showBullets={false}
                    showNav={true}
                    startIndex={selectedImageIndex}
                  >
                  </ImageGallery>
                </Panel>
              </>
            )}

            {githubUrl &&
              <div className="row mb-3">
                <div className="col-3 d-flex align-items-center">
                  <p>GitHub</p>
                </div>
                <div className="col-9 overflow-hidden">
                  <Link to={githubUrl} target="_blank">
                    {excerpt(githubUrl, 50)}
                  </Link>
                </div>
              </div>
            }
            {deployUrls.length !== 0 &&
              <div className="row mb-3">
                <div className="col-3 d-flex">
                  <p className='text-nowrap'>部屬網站</p>
                </div>
                <div className="col-9 overflow-hidden">
                  {deployUrls.map((e, idx) => {
                    return (
                      <li className='mb-4' key={idx}>
                        <Link to={e.url} target="_blank">
                          {e.displayText}
                        </Link>
                      </li>
                    )
                  })}
                </div>
              </div>
            }
            {content &&
              <div className="row mb-3">
                <div className="col-3 d-flex">
                  <p>內容</p>
                </div>
                <div className="col-9">
                  <MarkdownEditor.Markdown
                    source={content}
                    className={style.markdown}
                    style={{
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
            }

            {relatedLinks &&
              <div className="row mb-3">
                <div className="col-3 d-flex">
                  <p className='text-nowrap'>相關連結</p>
                </div>
                <div className="col-9 overflow-hidden">
                  {relatedLinks.map((e, idx) => {
                    return (
                      <li className='mb-4' key={idx}>
                        <Link to={e.url} target="_blank">
                          {e.displayText}
                        </Link>
                      </li>
                    )
                  })}
                </div>
              </div>
            }

            <div className="row mb-3">
              <div className="col-3 d-flex">
                <p>標籤</p>
              </div>
              <div className="col-9">
                <div
                  className="d-flex align-items-center flex-wrap"
                  style={{ opacity: "0.8" }}>
                  <i className="bi bi-tags-fill me-3" />
                  {tags.map((tag, idx) => {
                    return (
                      <Link to={`/repos?q=${tag}`} key={idx}>
                        <span className='me-3'>
                          {tag}
                        </span>
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>

            {tools &&
              <div className="row mb-3">
                <div className="col-3 d-flex">
                  <p>開發環境與工具</p>
                </div>
                {/* {JSON.stringify(tools)} */}
                <div className="col-9">
                  <MarkdownEditor.Markdown
                    source={tools}
                    className={style.markdown}
                    style={{
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
            }

            <div className="row mb-3">
              <div className="col-3 d-flex">
                <p>紀錄更新</p>
              </div>
              <div className="col-9">
                <p>{updatedAt === "" ? formattedDate(createdAt, 'includeTime') : formattedDate(updatedAt, 'includeTime')}</p>
              </div>
            </div>




          </main>

        </div>
      </div >
    </div >
  )
}
