
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { excerpt, formattedDate } from '../../utility/index'
import style from './_home.module.scss'
import Spinner from '../../components/spinner/Spinner';
import { useCollection } from '../../hooks/useCollection'
import Headline from './Headline';
// Hooks
import { useCategoryContext } from '../../hooks/useCategoryContext';

export default function Home({ setNavActive }) {
    //載入資料方法
    const { fetchDocument: fetchBlogs, response: resBlogs } = useCollection('blogs')
    const swiperRef = useRef();
    const { getCategoryName } = useCategoryContext() //分類表 

    //來源資料
    const [latestArticles, setLatestArticles] = useState([])


    useEffect(() => {
        setNavActive('home')
    }, [])

    useEffect(() => {
        const unsubLatestArticles = fetchBlogs([
            ["isDraft", "==", false],
            ["isPublic", "==", true],
        ], [["createdAt", "desc"]], 6);

        return () => {
            unsubLatestArticles();
        };
    }, []);

    //成功撈到資料之後設置為data
    useEffect(() => {
        if (resBlogs && resBlogs.success) {
            setLatestArticles(resBlogs.document || [])
        }
    }, [resBlogs])

    const featuredArticles = [
        {
            "id": "b1QvveX4BLOS4NUh2MDz",
            "title": "學習Git",
            "category": "tIxdclfJGguYrshH3aPY",
            "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2Fb1QvveX4BLOS4NUh2MDz%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2016.png?alt=media&token=ba6b55e9-9bab-42c8-a93b-1347fea06396",
            "createdAt": { "seconds": 1705040315, "nanoseconds": 685000000 },
        },
        { "id": "G4J7nG2JhBsOiHV9kwyg", "title": "Google Fonts免費開源字體", "category": "6UMNc446VEeFwJw7AIPf", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2FG4J7nG2JhBsOiHV9kwyg%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%202.png?alt=media&token=a9ded84b-7ded-4a49-950c-b27cac36ae30", "createdAt": { "seconds": 1704997252, "nanoseconds": 701000000 } },
        { "id": "y87VpdvAUfr132u12trn", "title": "pnpm讓多項目共享相同版本套件", "category": "6UMNc446VEeFwJw7AIPf", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2Fy87VpdvAUfr132u12trn%2Fpnpm.png?alt=media&token=35ba09f3-7071-4a4f-affe-293a37b0706f", "createdAt": { "seconds": 1704990968, "nanoseconds": 144000000 } }
    ]

    const recommendedArticles = [
        // 1.
        { "id": "y87VpdvAUfr132u12trn", "title": "pnpm讓多項目共享相同版本套件", "category": "6UMNc446VEeFwJw7AIPf", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2Fy87VpdvAUfr132u12trn%2Fpnpm.png?alt=media&token=35ba09f3-7071-4a4f-affe-293a37b0706f", "createdAt": { "seconds": 1704990968, "nanoseconds": 144000000 } },
        // 2.
        {
            "id": "G4J7nG2JhBsOiHV9kwyg",
            "title": "Google Fonts免費開源字體",
            "category": "6UMNc446VEeFwJw7AIPf",
            "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2FG4J7nG2JhBsOiHV9kwyg%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%202.png?alt=media&token=a9ded84b-7ded-4a49-950c-b27cac36ae30",
            "createdAt": { "seconds": 1704997252, "nanoseconds": 701000000 },
        },
        // 3.
        { "id": "l8F81OYr8fpsC1dadiUp", "title": "表格元素的響應式調整", "category": "6UMNc446VEeFwJw7AIPf", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2Fl8F81OYr8fpsC1dadiUp%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2026.png?alt=media&token=c9dab838-bc5c-474c-a7f0-1d78680ca6e9", "createdAt": { "seconds": 1705411171, "nanoseconds": 307000000 } },
        // 4.
        {
            "id": "vtfGfinhS7wGjW8ZKTQd",
            "title": "在github上部屬靜態網頁",
            "category": "OmsqnT4sGKr4ccyX9rfD",
            "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2FvtfGfinhS7wGjW8ZKTQd%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201.png?alt=media&token=3b8c92f6-5efc-4f46-9fb2-150becde9129",
            "createdAt": { "seconds": 1704963349, "nanoseconds": 947000000 },
        },
        // 5.
        {
            "id": "Eaj7Pro7fn9dww9dltKe",
            "title": "VSCode相關設定與常用套件集合",
            "category": "6UMNc446VEeFwJw7AIPf",
            "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/blogs%2FEaj7Pro7fn9dww9dltKe%2Fvscode.png?alt=media&token=3ea19f7c-37b0-49aa-bce8-a86a5b0dfb5c",
            "createdAt": { "seconds": 1704994274, "nanoseconds": 702000000 },
        },
    ]

    const reposSwiper = [
        { "id": "tjdkEmYmNywoN1AUKowx", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/repos%2FtjdkEmYmNywoN1AUKowx%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2029.png?alt=media&token=d8719d64-5d45-409d-af53-3510e28371dd", "repoTwName": "部落格樣式 NewsFlash", "description": "用純CSS+JS開發現代具備嚮應式設計的部落格樣式" },
        {
            "id": "GvnAVBAqREn7acfoY6Dl",
            "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/repos%2FGvnAVBAqREn7acfoY6Dl%2F%E9%83%A8%E8%90%BD%E6%A0%BC%E5%B0%81%E9%9D%A2_%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2015.png?alt=media&token=a8d3a125-ef84-4778-ba43-b6abfd3cc36f",
            "repoTwName": "FLEX精神時光屋",
            "description": "練習常見的10種flex排版"
        },
        { "id": "XsOImWqSCYKU3552ah00", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/repos%2FXsOImWqSCYKU3552ah00%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2025.png?alt=media&token=56b880d5-0de0-4dc4-996d-93c057dd6fc0", "repoTwName": "多頁網站", "description": "練習使用網頁元素以及多頁網站的樣式管理" },
        { "id": "AeqoAMHTUzQ7pH7f7xAY", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/repos%2FAeqoAMHTUzQ7pH7f7xAY%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2027.png?alt=media&token=aad166ee-fc7d-4611-b8a2-8cbee501ff4c", "repoTwName": "Unlock Leetcode", "description": "leetcode解題紀錄" },
        { "id": "tPLBUM6FWBO6cZuqAMWa", "coverImgUrl": "https://firebasestorage.googleapis.com/v0/b/chensblog-1ec7f.appspot.com/o/repos%2FtPLBUM6FWBO6cZuqAMWa%2F%E5%B7%A5%E4%BD%9C%E5%8D%80%E5%9F%9F%201%20%E8%A4%87%E6%9C%AC%2028.png?alt=media&token=1057a4b6-0c55-4826-80c7-5b3e4b57b519", "repoTwName": "50個有趣的網頁互動項目", "description": "透過50個小項目深化對瀏覽器DOM API熟練度" },

    ]

    function formatCreatedAt(createdAt) {
        const date = new Date(createdAt.seconds * 1000); // 将秒数转换为毫秒

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并确保两位数
        const day = ('0' + date.getDate()).slice(-2); // 确保两位数的日期

        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate;
    }

    const FeatureArticle = ({ article }) => {
        return (
            article && article.category && (
                <Link
                    className={`${style.article} ${style.articleImgContainer}`}
                    style={{ backgroundImage: `url(${article.coverImgUrl})` }}
                    to={`/blogs/${article.id}`}
                >
                    <div className={style.articleDataContainer}>
                        <div>
                            <span>{formatCreatedAt(article.createdAt)}</span>
                            <span className={style.articleDataSpacer}><span /></span>
                            <span >{getCategoryName(article.category)}</span>
                        </div>
                        <h3 className='h2 py-2'>{excerpt(article.title, 16)}</h3>
                    </div>
                    <span className={style.articleCategory}>
                        {getCategoryName(article.category)}
                    </span>
                </Link >
            )
        )
    }


    // 返回內容處理
    if (resBlogs.success === null) { return (<Spinner />) }// 載入元件
    return (
        <div className={`BelowNav`}>
            <div className=" row justify-content-center g-0">
                <div className="col-12 mt-4 py-3 px-4" >
                    <div className="row g-0">

                        <div className="col-12 col-lg-8 me-md-3">

                            <div className="row g-3">
                                {/* HEADLINE */}
                                < Headline />
                                {/* 焦點文章 */}
                                <div className={`col-12 col-md-6 `}>
                                    <FeatureArticle article={featuredArticles[0]} />
                                </div>
                                <div className={`col-12 col-md-6`}>
                                    <div className="row flex-column g-3">
                                        <div className={`col-12 col-md-6 w-100 `}>
                                            <FeatureArticle article={featuredArticles[1]} />
                                        </div>
                                        <div className={`col-12 col-md-6 w-100`}>
                                            <FeatureArticle article={featuredArticles[2]} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={`col-12 col-md my-3 my-lg-0`} >
                            <div className={`${style.sidebar} `}>
                                <h3 className={`${style.featuredContentTitle} ${style.title}`}>精選文章</h3>

                                {/* <p>{JSON.stringify(recommendedArticles.length)}</p> */}
                                {recommendedArticles.map((item, idx) => {
                                    return (
                                        <Link to={`/blogs/${item.id}`}
                                            className={style.trendingNewsBox}
                                            key={idx}
                                        >
                                            <div className={style.trendingNewsImageBox}>
                                                <span className={`${style.trendingNumber} placeItemsCenter`}
                                                >{`0${idx + 1}`}</span>
                                                <img src={item.coverImgUrl} className={style.articleImage} alt={item.title} />
                                            </div>
                                            <div className={style.trendingNewsData}>
                                                <div className={style.articleData}>
                                                    <span>{getCategoryName(item.category)}</span>
                                                    <span className={style.articleDataSpacer}><span /></span>
                                                    <span>{formatCreatedAt(item.createdAt)}</span>
                                                </div>
                                                <h3 className={`${style.articleTitle} ${style.title}`}>
                                                    {excerpt(item.title, 18)}
                                                </h3>
                                            </div>
                                        </Link >)
                                })}
                            </div>
                        </div>

                        <div className={`col-12 my-5`} >
                            <h2 className={`${style.title} ${style.sectionTitle} `} data-name="  快速瀏覽">
                                快速瀏覽
                            </h2>
                            <div className={`${style.swiper} mt-5`}>
                                <div className={` ${style.swiperSlide}`}>
                                    <button
                                        onClick={() => swiperRef.current?.slidePrev()}>
                                        <i className="bi bi-caret-left-fill"></i>
                                    </button>
                                    <button onClick={() => swiperRef.current?.slideNext()}>
                                        <i className="bi bi-caret-right-fill"></i>
                                    </button>
                                </div>
                                <Swiper
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper;
                                    }}
                                    spaceBetween={20}
                                    breakpoints={{
                                        576: { slidesPerView: 1 },
                                        768: { slidesPerView: 2 },
                                        992: { slidesPerView: 3 },
                                    }}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    effect={'slide'}
                                // onSlideChange={() => console.log('slide change')}
                                // onSwiper={(swiper) => console.log(swiper)}
                                >
                                    {
                                        reposSwiper.map((repo, idx) => (
                                            <SwiperSlide
                                                key={repo.id}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <Link to={`/repos/${repo.id}`} className="article dGrid">
                                                    <div className="olderPostsArticleImageWrapper">
                                                        <img src={repo.coverImgUrl} className="articleImage" alt="" />
                                                    </div>
                                                    <div className={style.articleDataContainer}>

                                                        <h3 style={{ fontSize: '2rem' }}>{repo.repoTwName}</h3>
                                                        <span>{repo.description}</span>
                                                    </div>
                                                </Link >
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>


                        <div className={`col-12 mb-3`} >
                            <h2 className={`${style.title} ${style.sectionTitle} mb-5`} data-name="最新文章">
                                最新文章
                            </h2>
                            <div className={`${style.articlesGroup} row g-5 g-sm-4`}>
                                {/* {JSON.stringify(latestArticles)} */}
                                {
                                    latestArticles.map((blog, idx) => (
                                        <div className="col-12 col-md-6 " key={blog.id}>
                                            <Link to={`/blogs/${blog.id}`} key={blog.id}>
                                                <article className='my-3 my-sm-2 my-md-1'>
                                                    <div className={style.articleImgWrapper}>
                                                        <img src={blog.coverImgUrl} alt={blog.title} />
                                                    </div>

                                                    <div className={style.articleDataWrapper}>
                                                        <div className="d-flex align-items-center">
                                                            <span>{formattedDate(blog.createdAt)}</span>
                                                            <span className={style.articleDataSpacer}><span /></span>
                                                            <span>{getCategoryName(blog.category)}</span>
                                                        </div>

                                                        <h4 className={style.title}>{excerpt(blog.title, 30)}</h4>
                                                        <p>{excerpt(`${blog.description}`, 20)}</p>
                                                    </div>
                                                </article>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}