// React related
import { useContext } from "react";
// Context
import { AuthContext } from "../context/AuthContext";
// Libraries
// Database or services
// Styles
// Components
// Pages
// Hooks

export const useAuthContext = ()=>{
    const context = useContext(AuthContext)
    if(!context){
        throw Error('useAuthContext必須在AuthContextProvider內使用')
    }
    return context
}