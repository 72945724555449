// React related
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Link } from 'react-router-dom'
// Context
// Libraries
import { excerpt, formattedDate } from '../../utility/index'
// Database or services
// Components
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
// Styles
import style from '../blogs/_blogs.module.scss'
// Pages
// Hooks
import { useCategoryContext } from '../../hooks/useCategoryContext';
import { useCollection } from '../../hooks/useCollection'

export default function CategoryBlog({ setNavActive }) {
    const { categoryList, getCategoryID } = useCategoryContext() //分類表
    const { category } = useParams(); //新聞

    //載入方法
    const { fetchDocument: fetchBlogs, response: resBlogs } = useCollection('blogs')

    // 定義資料請求狀態
    const [data, setData] = useState([]) //來源資料

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const dataPerPage = 10;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);
    // 換頁的方法
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    // 啟用監聽
    useEffect(() => {
        setNavActive('blogs')
    }, [])

    //從名稱找到ID再去資料庫撈資料
    useEffect(() => {
        const categoryId = getCategoryID(category)
        if (categoryId !== null) {
            const unsubBlogs = fetchBlogs([
                ["isDraft", "==", false],
                ["isPublic", "==", true],
                ["category", "==", getCategoryID(category)]
            ])
            return () => { unsubBlogs() };
        }
    }, [category, categoryList])

    //成功撈到資料之後設置為data
    useEffect(() => {
        if (resBlogs && resBlogs.success) {
            setData(resBlogs.document || [])
        }
    }, [resBlogs])
    if (fetchBlogs === null) { return (<Spinner />) }// 載入元件

    return (
        <div className={`BelowNav`}>
            <div
                className={style.hero}
                style={{ minHeight: "400px", backgroundPosition: "55% 60%" }}
            />
            <div className="container row justify-content-center">
                <div className="col-12">
                    <div className="row justify-content-between g-5">
                        {/* 主要區塊 */}
                        <main className="col-12 ">
                            {/* 🚧文章列表 */}
                            <section className={style.articlesGroup}>
                                <div className={style.titleGroup}>
                                    <h3>文章分類：{category}</h3>
                                </div>
                                <div className="row">
                                    {currentData?.map((blog, idx) => (
                                        <Link to={`/blogs/${blog.id}`} key={blog.id} className='col-12 col-lg-6'>
                                            <article>
                                                <div className={style.articleImgWrapper}>
                                                    <img src={blog.coverImgUrl} alt={blog.title} />
                                                </div>

                                                <div className={style.articleDataWrapper}>
                                                    <div className="d-flex align-items-center">
                                                        <span>{formattedDate(blog.createdAt)}</span>
                                                        <span className={style.articleDataSpacer} />
                                                        <span>{category}</span>
                                                    </div>

                                                    <h4 className={style.title}>{blog.title}</h4>
                                                    <p>{excerpt(`${blog.description}Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum quos corporis dolorem porro error numquam sunt tempora labore animi unde deleniti quibusdam, dignissimos itaque necessitatibus saepe repudiandae quae minima ullam.`, 30)}</p>
                                                </div>
                                            </article>
                                        </Link>
                                    ))
                                    }
                                </div>
                            </section>

                            <Pagination
                                dataPerPage={dataPerPage}
                                totalData={data.length}
                                activePage={currentPage}
                                paginate={paginate}
                            />
                        </main>


                    </div>
                </div>
            </div>
        </div>
    )
}
