// React related
import { useReducer, useEffect, useState } from "react"
// Context
// Libraries
// Database or services
import { firestore } from "../firebase/config"
import { collection, addDoc, Timestamp, doc, deleteDoc, updateDoc, setDoc } from "firebase/firestore";
// Components
// Pages
// Styles
// Hooks

// 初始狀態 (在hook外創建，因為不需要每次使用hook時都創建副本)
let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null,
}

// 定義reducer
// Reducer函數 可以想像是useState裡面的setter，要改變其一屬性的時候，先展開全數屬性後，再根據要修改的key複寫新的value
const firestoreReducer = (state, action) => {
    switch (action.type) {
        case "IS_PENDING":
            return { success: false, isPending: true, error: null, document: null }
        case "ERROR":
            return { success: false, isPending: false, error: action.payload, document: null }
        case "ADDED_DOCUMENT":
            return { success: true, isPending: false, error: null, document: action.payload }
        case "UPDATED_DOCUMENT":
            return { success: true, isPending: false, error: null, document: action.payload }
        default:
            return state
    }
}

export const useFirestore = (collectionName) => {
    // 使用useReducer管理"回應"狀態，並定義useReducer #reducer 
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    // 檢查組件是否被卸載
    const [isCancelled, setIsCancelled] = useState(false)

    // 指定資料庫中集合的路徑 collection ref
    const ref = collection(firestore, collectionName);
    // 只有元件未卸載的情況下才dispatch
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // 在集合中新增資料
    const addDocument = async (data) => {
        dispatch({ type: "IS_PENDING" })
        try {
            const createdAt = Timestamp.fromDate(new Date())
            const addedDocument = await addDoc(ref, { ...data, createdAt })
            dispatchIfNotCancelled({ type: "ADDED_DOCUMENT", payload: addedDocument })
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    // 在集合中新增資料(自定義ID)
    const addDocumentWithCustomID = async (customID, data) => {
        dispatch({ type: "IS_PENDING" })
        try {
            const createdAt = Timestamp.fromDate(new Date())
            const docRef = doc(ref, customID); // 使用指定的自訂ID
            await setDoc(docRef, { ...data, createdAt });
            dispatchIfNotCancelled({ type: "ADDED_DOCUMENT", payload: { id: customID, ...data, createdAt } });
        } catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
        }
    }

    // 在集合中刪除資料
    const deleteDocument = async (id) => {
        dispatch({ type: "IS_PENDING" })
        try {
            await deleteDoc(doc(ref, id))
            dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
        }
        catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: '執行刪除操作時出現錯誤' })
        }
    }

    // 在集合中更新資料
    const updateDocument = async (id, data) => {
        dispatch({ type: "IS_PENDING" })
        try {
            const updatedAt = Timestamp.fromDate(new Date())
            await updateDoc(doc(ref, id), { ...data, updatedAt })
            dispatchIfNotCancelled({ type: 'UPDATED_DOCUMENT' }) // 更新成功後的操作
        }
        catch (err) {
            console.log(err);
            dispatchIfNotCancelled({ type: 'ERROR', payload: '執行更新操作時出現錯誤' })
        }
    }
    //這個返回的函數會在組件卸載時執行
    useEffect(() => {
        return () => {
            setIsCancelled(true)
        };
    }, []);

    return { addDocument, addDocumentWithCustomID, deleteDocument, updateDocument, response }

}
